import React from 'react'
import { Slider } from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux"
import {changeLineHeight,changeSampleSize,changeLineWidth,changeWavePosition, changeRadius,changeHeartPosition} from "../../../features/SoundArtFeature/soundArtSlice"

export default function HeartConfigComponent() {

    const dispatch = useDispatch()
    const soundwaveType = useSelector(state => state.soundArtReducer.soundwaveType)
    const wavePosition = useSelector(state => state.soundArtReducer.wavePosition)
    const lineWidth = useSelector(state => state.soundArtReducer.lineWidth)
    const lineHeight = useSelector(state => state.soundArtReducer.lineHeight)
    const sampleSize = useSelector(state => state.soundArtReducer.sampleSize)
    const radius = useSelector(state => state.soundArtReducer.radius)
    const heartPosition = useSelector(state => state.soundArtReducer.heartPosition)
    


    const sliderStyle = {
        marginLeft: "2em",
        paddingRight: "2em",
        root: {
          color: "#5f00ba",
        },
      };
      const containerStyle = {
        boxSizing: " border-box",
        display: "flex",
        flexDirection: "column",
        lineHeight: "1",
        fontFamily: "inherit",
        width: "100%",
        padding: "10px 15px",
        backgroundColor: "#fff",
  //A647FF ELECTRIC PURPLE
      };
  

    return (
        <div style={containerStyle}>
          <p id="wc__linewidth" aria-hidden="true" style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
            Line Thickness:
          </p>
    
          <Slider
            value={lineWidth}
            aria-labelledby="wc__linewidth"
            valueLabelDisplay="auto"
            defaultValue={lineWidth}
            step={0.01}   
            min={0}
            max={20}
            onChange={(event,newValue) => {event.preventDefault(); dispatch(changeLineWidth(newValue))}}
            style={{ sliderStyle }}
          />   
          <p id="wc_waveheight" aria-hidden="true" style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
            Wave Height:
          </p>
         
          <Slider
            value={lineHeight}
            aria-labelledby="wc_waveheight"
            valueLabelDisplay="auto"
            defaultValue={lineHeight}
            step={1}   
            min={-200}
            max={70}
            onChange={(event,newValue) => {event.preventDefault(); dispatch(changeLineHeight(newValue))}}
            style={{ sliderStyle }}
            />
  
         
          <p id="wc__complexity" aria-hidden="true" style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
            Complexity (Sample size):
          </p>
        
      
          <Slider
            value={sampleSize}
            aria-labelledby="wc__complexity"
            valueLabelDisplay="auto"
            defaultValue={sampleSize}
            step={10}  
            min={24}
            max={2048}
            onChange={(event,newValue) => {event.preventDefault(); dispatch(changeSampleSize(newValue))}}
            style={{ sliderStyle }} />
  
       
          <React.Fragment>

          <p style={{ color: "#000", fontWeight: "bold",textAlign: "center" }}>
          Radius:
        </p>
   
        <Slider
          defaultValue={radius}
          value={radius}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          step={0.1}
          min={1}
          max={40}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeRadius(newValue))}}
          style={{ sliderStyle }}
        />

        <p style={{ color: "#000", fontWeight: "bold",textAlign: "center" }}>
          Heart Position:
        </p>
   
        <Slider
          defaultValue={heartPosition}
          value={heartPosition}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          step={0.01}
          min={-1}
          max={1}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeHeartPosition(newValue))}}
          style={{ sliderStyle }}
        />

          
          <p id="wavepos" aria-hidden="true" style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
            Wave Position:
          </p>
        
  
          <Slider
            value={wavePosition}
            aria-labelledby="wavepos"
            valueLabelDisplay="auto"
            defaultValue={wavePosition}
            step={0.01}
            min={0.12}
            max={1}
            onChange={(event,newValue) => {event.preventDefault(); dispatch(changeWavePosition(newValue))}}
            style={{ sliderStyle }}
     />
          </React.Fragment>
        </div>
      );
    
}
