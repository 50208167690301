import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import { ThemeProvider, createMuiTheme, } from "@material-ui/core/styles";
import CartPage from "./Components/ShopifyComponents/CartPage";
import { v4 as uuidv4 } from "uuid";
import TemplateConfigComponent from "./Components/SidebarComponents/TemplatingComponents/TemplateConfigComponent";
import TemplateSelectComponent from "./Components/SidebarComponents/TemplatingComponents/TemplateSelectComponent"
import FixedSidebar from "./Components/FixedSidebar";
import FooterComponent from "./Components/FooterComponent"
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"
import { SIZE, MEDIUM, CANVASSMALLVARIANTID, CANVASMEDIUMVARIANTID, CANVASLARGEVARIANTID, PRINTSMALLVARIANTID, PRINTMEDIUMVARIANTID, PRINTLARGEVARIANTID, PRINTSMALLFRAMEDVARIANTID, PRINTSMEDIUMFRAMEDVARIANTID, PRINTSLARGEFRAMEDVARIANTID } from "./constants"
import { useAuthState } from "react-firebase-hooks/auth"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useMediaQuery } from 'react-responsive'
import { isEqual } from "lodash-es";
export default function App(props) {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 414px)'
  })

  //Firebase:
  const firebaseConfig = {
    apiKey: "AIzaSyBSjtxRCFQ0W_3AnpSmLFOEJsXSAh9rmd8",
    authDomain: "asvizprod.firebaseapp.com",
    databaseURL: "https://asvizprod.firebaseio.com",
    projectId: "asvizprod",
    storageBucket: "asvizprod.appspot.com",
    messagingSenderId: "844406564528",
    appId: "1:844406564528:web:eff86309f9d6c5a210bd75",
    measurementId: "G-FT90HL26S6"
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }


  const auth = firebase.auth();
  const firestore = firebase.firestore()
  const storage = firebase.storage()

  const currentTemplate = useSelector(state => state.soundArtReducer.currentTemplate)
  const templateSelected = useSelector(state => state.soundArtReducer.templateSelected)
  const [currentDesigns, setCurrentDesigns] = useState([])
  const [checkout, setCheckout] = useState({ lineItems: [] })
  const [products, setProducts] = useState([])
  const [shop, setShop] = useState([])
  const [options, showCanvasOptions] = useState(false);
  const [audio, setAudio] = useState(null);
  const [recording, setRecording] = useState(false);
  const [userAudios, setUserAudios] = useState([])

  useEffect(() => {

    firebase.auth().signInAnonymously()
      .then((user) => {
        console.log(user)
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
    props.client.checkout.create().then((res) => {
      setCheckout(res)

      props.client.collection.fetchAllWithProducts().then((collections) => {
        console.log([collections[1].products]);
        setProducts([collections[1].products])
      });
    })
    props.client.shop.fetchInfo().then((res) => {
      setShop(res)
    });
  }, [props.client.checkout, props.client.shop, props.client.collection, userAudios])

  const addUserAudio = (audio) => {
    if (userAudios.map((e => e.id !== audio.id))) {
      if (userAudios.length === 0) audio.index = 0;
      else { audio.index = userAudios.length };
      audio.id = uuidv4();
      let ua = userAudios; ua.push(audio); setUserAudios(ua);
    } else {
      console.error("duplicate audio provided, something went wrong!");
      // Might want to add a new generation of an id here and call the func again?
    }
  }

  const removeUserAudio = (audio) => {
    if (userAudios(e => e.id === audio.id)) {
      userAudios.splice(audio.index, 1);
    } else {
      console.error("tried to delete an audio that is no longer stored in user audios!");
    }
  }
  const updateUserAudio = (audio) => {
    if (userAudios(e => e.id === audio.id)) {
      userAudios[audio.index] = audio;
    } else {
      console.error("tried to update a user audio that is not present in the user audio array!");
    }
  }

  const prepareCheckout = (checkedOutDesign) => {

    if (!checkedOutDesign.uid) {
      console.log(" NO UID STRING: " + checkedOutDesign.uid);
      checkedOutDesign.uid = uuidv4();
      checkedOutDesign.paramString = `/?checkoutid=${checkout.id}&uid=${checkedOutDesign.uid}`;
      addVariantToCart(getCurrentVariantID(checkedOutDesign), 1, checkedOutDesign.uid);
      let products = currentDesigns
      currentDesigns.push(checkedOutDesign)

      setCurrentDesigns(products)
      console.log("Current products: ");
      console.log(currentDesigns);
    } else {
      console.log("UID STRING: " + checkedOutDesign.uid);
      console.log(
        "PARAM STRING OF DESIGN WITH EXISTING UID: " +
        checkedOutDesign.paramString
      );
      let originalDesigns = currentDesigns;

      for (let i = 0; i < originalDesigns.length; i++) {
        if (originalDesigns[i].uid === checkedOutDesign.uid) {
          console.log("UID STRING MATCH: " + checkedOutDesign.uid);
          originalDesigns[i] = checkedOutDesign;
        }
      }
      setCurrentDesigns(originalDesigns)

    }



  }



  // SHOPIFY METHODS:

  function getCurrentVariantID(design) {
    if (!design) return null;
    if (!design.medium) return null;
    if (!design.size) return null;


    if (design.medium === MEDIUM.CANVAS) {
      if (design.size === SIZE.SMALL) return CANVASSMALLVARIANTID

      else if (design.size === SIZE.MEDIUM) return CANVASMEDIUMVARIANTID

      else if (design.size === SIZE.LARGE) return CANVASLARGEVARIANTID

    }

    else if (design.medium === MEDIUM.PRINT) {
      if (design.size === SIZE.SMALL) return PRINTSMALLVARIANTID

      else if (design.size === SIZE.MEDIUM) return PRINTMEDIUMVARIANTID

      else if (design.size === SIZE.LARGE) return PRINTLARGEVARIANTID

    }
    //TODO: NEED TO IMPLEMENT THE FRAMING UPSELLS IN THE STORE AND LINK THE VARIANT IDS
    else if (design.medium === MEDIUM.FRAMED) {
      if (design.size === SIZE.SMALL) return PRINTSMALLFRAMEDVARIANTID

      else if (design.size === SIZE.MEDIUM) return PRINTSMEDIUMFRAMEDVARIANTID

      else if (design.size === SIZE.LARGE) return PRINTSLARGEFRAMEDVARIANTID
    }


    return null
  }

  function addVariantToCart(variantId, quantity, _key) {
    console.log("adding variant: ");
    console.log(variantId);
    if (!_key) _key = uuidv4();
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: [{ key: "_uid", value: _key }, { key: "_Checkout ID", value: checkout.id }],
      },
    ];

    if (lineItemsToAdd) {
      return props.client.checkout
        .addLineItems(checkout.id, lineItemsToAdd)
        .then((res) => {
          setCheckout(res)
        });
    }
  }

  function updateQuantityInCart(lineItemId, quantity) {
    const { id } = checkout
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    return props.client.checkout
      .updateLineItems(id, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
      });
  }

  function removeLineItemInCart(lineItemId) {
    console.log(lineItemId);
    const { id } = checkout
    const lineItemsToRemove = [lineItemId]

    return props.client.checkout
      .removeLineItems(id, lineItemsToRemove)
      .then((res) => {
        setCheckout(res)
      });
  }

  //______


  const removeDesign = (i) => {
    let array = [...currentDesigns];

    const variantID = getCurrentVariantID(currentDesigns[i])
    if (variantID) {
      removeLineItemInCart(checkout.lineItems[i].id);
      if (i !== -1) {
        array.splice(i, 1);
        setCurrentDesigns(array)
      }
    }

  }
  const recordingUploaded = (buffer) => {
console.log("buffer in APP");
console.log(buffer);
setRecording(buffer)

  } 

  function handleContinue() {
    showCanvasOptions(true)
  }
  function backToPersonalize() {
    showCanvasOptions(false)
  }

  const customTheme = createMuiTheme({
    palette: {
      primary: { main: "#5f00ba" }
    },
    overrides: {

      MuiSlider: {
        root: {
          color: '#5f00ba',
        }
      }
    }
  });

  return (
    <BrowserRouter>
      <Route
        path="/"
        exact
        render={(props) => (

          <div className="templateSelectGrid">
            <ThemeProvider theme={customTheme}>
              <FixedSidebar mobile={isMobile} userAudios />
            </ThemeProvider>
            <TemplateSelectComponent {...props} />

            <FooterComponent />
          </div>
        )}
      />
      <Route
        path="/design"
        exact
        render={(props) => (
          <div className="designContainer">
            <ThemeProvider theme={customTheme}>
              <FixedSidebar showDescription={false}
                onSelect={templateSelected ? true : false}
                show={templateSelected ? true : false}
                handleContinue={(e) => handleContinue(e)}
                backToPersonalize={(e) => backToPersonalize(e)}
                showOptions={options}
                mobile={isMobile}
                onAudioUploaded={(file) => setAudio(file)}
                recordingUploaded= {(buffer) => recordingUploaded(buffer)}
                userAudios={userAudios}
              /></ThemeProvider>
            <TemplateConfigComponent
              prepareCheckout={(design) => prepareCheckout(design)}
              template={currentTemplate}
              templateSelected={templateSelected}
              audio={audio}
              addUserAudio={addUserAudio}
              isRecording={recording}
              userAudios={userAudios}

              {...props}
            />
            <FooterComponent />
          </div>

        )}
      />
      <Route
        path="/cart"
        exact
        strict
        render={(props) => (
          <ThemeProvider theme={customTheme}>
            <CartPage
              designs={currentDesigns}
              addVariantToCart={addVariantToCart}
              updateQuantityInCart={updateQuantityInCart}
              removeLineItemInCart={removeLineItemInCart}
              removeDesign={removeDesign}
              cartOpen={true}
              checkout={checkout}
              storage={storage}
              products={products}
              client={props.client}
              {...props}
            /></ThemeProvider>
        )}
      />
    </BrowserRouter>
  );

}


