import React from "react";
import ColorFromSwatchComponent from "./ColorFromSwatchComponent";
import { Button, MenuItem, Select } from "@material-ui/core";
import ColorPaletteComponent from "./ColorPaletteComponent";
import "./ColorPaletteComponent.css";
import PaletteCreatorComponent from "./PaletteCreatorComponent";
import ColorGradientComponent from "./ColorGradientComponent";
import { useDispatch, useSelector } from "react-redux";
import { changeCanvasColor, changeColorMode, changeGradient, changePalette, changeSubtitleColor, changeTitleColor, toggleCreatePalette, changeMonoWaveColor } from "./colorFeatureSlice"

export default function ColorComponent() {

  const dispatch = useDispatch();

  // SUBSCRIPTIONS:

  const currentPalette = useSelector(state => state.colorReducer.currentPalette)
  const currentGradient = useSelector(state => state.colorReducer.currentGradient)
  const colorMode = useSelector(state => state.colorReducer.colorMode)
  const creatingPalette = useSelector(state => state.colorReducer.createPaletteEnabled)
  const canvasColor = useSelector(state => state.colorReducer.canvasColor)
  const palettes = useSelector(state => state.colorReducer.palettes)
  const gradients = useSelector(state => state.colorReducer.gradients)
  const currentMonoColor = useSelector(state => state.colorReducer.monoColor)
  const soundwaveType = useSelector(state => state.soundArtReducer.soundwaveType)

  //____________________________________________________________________________________ 

  const colorModes = ["palette", "gradient", "mono"];

  const containerStyle = {
    alignItems: "center",
    lineHeight: "1",
    textAlign: "center",
    fontFamily: "inherit",
    width: "100%",
    padding: "10px 15px",
    backgroundColor: "#fff",
  };

  const item = { margin: "0 5px" };
  function availableGradients() {
    return gradients.map((el, index) => (
      <MenuItem aria-label="Gradient" value={el} key={index}>
        <ColorGradientComponent key={index} color1={el[0]} color2={el[1]} />
      </MenuItem>
    ));
  }

  function availablePalettes() {
    return palettes.map((el, index) => (
      <MenuItem aria-label="Color Palette" value={el} key={index}>
        <ColorPaletteComponent key={index} colors={el} />
      </MenuItem>
    ));
  }



  return (
    <div style={containerStyle}>
     { soundwaveType !== "picture" ? <h2 style={{ display: "block", paddingBottom: "1em" }}>Color Style</h2> : null}
      {soundwaveType !== "picture" ? <div style={{
        display: "flex", justifyContent: "center",
        textAlign: "center", paddingBottom: "2em",
      }}> {colorModes.map((el, index) => (
        <Button variaton="contained" color="primary" style={{ marginRight: "1em" }} onClick={() => dispatch(changeColorMode(el))} key={index} variant="contained">{el}</Button>
      ))}
      </div> : null}


      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          width: "100%"
        }}
      >

        {" "}

        <div style={item}>
          <h3>Background Color: </h3>{" "}
          <ColorFromSwatchComponent
            label="Canvas Color"
            handleColorChange={(color) => dispatch(changeCanvasColor(color))}
            currentColor={canvasColor}
          />
        </div>
        
      </div>

      {soundwaveType !== "picture" ? (
        <div style={{ textAlign: "center" }}>
          <h3>Wave Color: </h3>{" "}
          {colorMode !== "mono" ? (
            <div>
              <Select
                value={colorMode === "gradient" ? currentGradient : currentPalette}
                onChange={(event) => dispatch(colorMode === "palette" ? changePalette(event.target.value) : changeGradient(event.target.value))}
                style={{ width: "220px" }}
              >
                {colorMode === "gradient" ? availableGradients() : availablePalettes()}

              </Select>
            </div>
          ) : (
            <ColorFromSwatchComponent
              handleColorChange={(color) => dispatch(changeMonoWaveColor(color))}
              currentColor={currentMonoColor}
            />
          )}
          {creatingPalette ? (
            <PaletteCreatorComponent clrMode={colorMode} />
          ) : (
            <Button
              variant="contained"
              onClick={() => dispatch(toggleCreatePalette())}
              style={{
                marginTop: "2em", textAlign: "center", backgroundColor: "#5f00ba",
                color: "white",
              }}
            >
              {colorMode === "gradient" ? "Create Your Own Gradient" : "Create Your Own Palette"}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );

}
