import { ButtonBase, Button } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { changeOrientation, changeMedium, changeSize } from "../../../features/SoundArtFeature/soundArtSlice";
import ImageIcon from "@material-ui/icons/Image";
import canvasPrintImage from "../../../assets/canvas_print.jpeg"
import framesImage from "../../../assets/fine-art-prints.jpeg"
import framedPrintImage from "../../../assets/IMG_1185_400x400.webp"
export default function CanvasOptionsComponent(props) {

  const size = useSelector(state => state.soundArtReducer.size)
  const medium = useSelector(state => state.soundArtReducer.medium)


  const dispatch = useDispatch()
  const containerStyle = {
    boxSizing: "border-box",
    alignItems: "center",
    lineHeight: "1",
    textAlign: "center",
    fontFamily: "inherit",
    width: "100%",
    padding: "10px 15px",
    backgroundColor: "#fff",
    boxShadow: " 0 0 3px 0 rgba(0, 0, 0, 0.2)",
  };

  const selectedStyle = {
    backgroundColor: "#5f00ba",
    color: "white"
  }
  const notSelectedStyle = {

  }



  return (
    <div>

      <div style={containerStyle}>

        <h2 aria-label="Select your preferred Medium">Select Medium</h2>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Button style={medium === "Print" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeMedium("Print"))}>Print  <span style={{ fontSize: "0.7rem", paddingLeft: "0.7em" }}>(from $34)</span></Button>
          <Button style={medium === "Framed Print" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeMedium("Framed Print"))}>Framed Print  <span style={{ fontSize: "0.7rem", paddingLeft: "0.7em" }}>(from $78)</span></Button>
          <Button style={medium === "Canvas" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeMedium("Canvas"))}>Canvas <span style={{ fontSize: "0.7rem", paddingLeft: "0.7em" }}>(from $80)</span></Button>
        </div>
        <div style={{
          display: "flex", paddingTop: "2em", paddingBottom: "1em"
        }}>
          <img src={medium === "Print" ? framedPrintImage : medium === "Framed Print" ? framesImage : canvasPrintImage} height="200px" width="100%" />
          <p style={{ textAlign: "left", marginLeft: "1em" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
        </div>

      </div>
      <div style={containerStyle}>

        <h2 aria-label="Select your preferred Size">Select Size</h2>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Button style={size === "Small (16 x 12)" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeSize("Small (16 x 12)"))}>Small <span style={{ fontSize: "0.7rem", paddingLeft: "0.7em" }}>(16 x 12)</span></Button>
          <Button style={size === "Medium (24 x 18)" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeSize("Medium (24 x 18)"))}>Medium <span style={{ fontSize: "0.7rem", paddingLeft: "0.7em" }}>(24 x 18)</span></Button>
          <Button style={size === "Large (32 x 24)" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeSize("Large (32 x 24)"))}>Large <span style={{ fontSize: "0.7rem", paddingLeft: "0.7em" }}>(32 x 24)</span></Button>
        </div>
      </div>
    </div>
  );

}
