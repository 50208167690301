import React from "react";

function ColorSwatchComponent(props) {

  const swatchStyle = {
    border: "none",
    padding: "15px 15px",
    marginLeft: "4px",
    marginRight: "4px",
    marginTop: "4px",
    display: "inline",
    background: `${props.color}`,
    flexBasis: "1",
    borderRadius: "10px",

  };
  return (
    <div style={swatchStyle}></div>
  );

}

export default ColorSwatchComponent;
