import React from "react";
import "./UserAudioComponent.css";
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';


export default function UserAudioComponent(props) {


  const { selected, image, name } = props;

  return (
    <div
      aria-roledescription="button"
      role="button"
      className={selected ? "selectedBuffer" : "unselectedBuffer"}
    //     onClick={() => EventEmitter.dispatch("UserAudioChanged", this)}
    >
      <div className="ua_wrapper">
        {selected ? (
          <div className="ua_img"> <IconButton fontSize="large" color="primary" onClick={(e) => props.playAudio(e)} data-playing="false" role="switch" aria-checked="false" width="70px" height="56px">{props.isPlaying ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon fontSize="large" />}</IconButton></div>
        ) : null}
        <audio id={props.myAudioID} src={props.audio}></audio>
        <h3 className="ua_title">{name}<br /><p style={{ fontWeight: "normal", fontSize: "12px" }}>Type: {props.audioType}</p><p style={{ fontWeight: "normal", fontSize: "12px" }}>Date Added: {props.dateAdded}</p></h3>

      </div>

    </div >
  );

}
