import React from "react";
import { Slider } from "@material-ui/core";
import {useSelector,useDispatch} from "react-redux"
import {changeRadius,changeSampleSize,changeLineWidth} from "../../../features/SoundArtFeature/soundArtSlice"
export default function RadialConfigComponent (props) {
  const dispatch = useDispatch()
  const lineWidth = useSelector(state => state.soundArtReducer.lineWidth)
  const radius = useSelector(state => state.soundArtReducer.radius)
  const sampleSize = useSelector(state => state.soundArtReducer.sampleSize)
  



    const sliderStyle = {
      marginLeft: "2em",
      paddingRight: "2em",
    };
    return (
      <div
        style={{ paddingLeft: "2em", paddingRight: "2em"}}
      >
        <p style={{ color: "#000", fontWeight: "bold", textAlign: "center"  }}>Line Thickness:</p>
     
        <Slider
          value={lineWidth}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          defaultValue={0}
          step={0.1}
          min={0}
          max={20}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeLineWidth(newValue))}}
          style={{ sliderStyle }}
        />
        <p style={{ color: "#000", fontWeight: "bold",textAlign: "center" }}>
          Radius:
        </p>
   
        <Slider
          defaultValue={radius}
          value={radius}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          step={1}
          min={1}
          max={600}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeRadius(newValue))}}
          style={{ sliderStyle }}
        />

        <p style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
          Complexity (Sample size):
        </p>

        <Slider
          value={sampleSize}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          defaultValue={sampleSize}
          step={1}
          min={24}
          max={2048}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeSampleSize(newValue))}}
          style={{ sliderStyle }}
        />
      </div>
    );
  
}
