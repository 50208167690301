import React from 'react'

export default function ColorGradientComponent(props) {

    const {color1,color2} = props;
    const containerStyle = {  width: "189px", height: "30px"  }
    const paletteStyle = {width:"195px",height:"30px",background: `linear-gradient(0.25turn,${color1}, ${color2})`,borderRadius:"5px",}
    const myColors = <div style={paletteStyle}></div>
    return (
        <div style={containerStyle}>
            {myColors}
        </div>
    )
}
