import { createSlice } from "@reduxjs/toolkit";
import wedding from "../../assets/weddingtest2.jpg"
import bg from "../../assets/fatherdaughterintent.jpg"
import { ILOVEYOU, LINEARTEMPLATE, LINETEMPLATE, IMAGEINWAVE, IMAGEBACKGROUND, RADIAL, RADIALCIRCLE, HEARTTEMPLATE } from "../../Components/SidebarComponents/TemplatingComponents/Templates";
import { ORIENTATION, MEDIUM, SOUNDWAVETYPE, SIZE } from "../../constants"


const soundArtSlice = createSlice(
    {
        name: "soundart",
        initialState: {
            currentAudio: IMAGEINWAVE.audio,
            currentBuffer: [],
            currentUnsampledBuffer: [],
            templateSelected: false,
            template: "cleanradialtemplate",
            soundwaveType: SOUNDWAVETYPE.PICTURE,
            orientation: ORIENTATION.LANDSCAPE,
            landscape: true,
            drawMode: "picture-line",
            waveHeight: 0,
            paddingWidth: 0,
            lineWidth: 3,
            lineHeight: 1,
            barWidth: 0,
            barHeight: 0,
            radius: 90,
            spacing: 0,
            sampleSize: 2048,
            waveImage: wedding,
            imageHorizontal: 0,
            heartPosition:0.15,
            imageVertical: -274,
            bgImage: bg,
            textInWave: false,
            subtitleDisabled: false,
            useBG: false,
            scaledHeight: -30,
            heartTemplate: false,
            wavePosition: 0.25,
            medium: MEDIUM.PRINT,
            size: SIZE.MEDIUM,
            isSquare: false,
            isLoading: true,
            currentIsMonoBuffer: false,
            checkedOutDesign: [],
            exportingCanvas: false,
            params: {},
            clearCanvas: false,
            offsetX: 0,
            offsetY: 0
        },
        reducers: {
            prepareCheckout(state, { payload }) {
                state.checkedOutDesign.push(payload)
            },

            getStateAsParameters(state) {
                let { template, soundwaveType, orientation, drawMode } = state;
                let params = {
                    template, soundwaveType, orientation, offsetX: state, offsetY: state.offsetY, drawMode, templateSelected: "true", landscape: state.landscape.toString(), waveHeight: state.waveHeight.toString(),
                    paddingWidth: state.paddingWidth.toString(), lineWidth: state.lineWidth.toString(), lineHeight: state.lineHeight.toString(), barWidth: state.barWidth.toString(),
                    barHeight: state.barHeight.toString(), radius: state.radius.toString(), spacing: state.spacing.toString(), sampleSize: state.sampleSize.toString(),
                    textinWave: state.textInWave.toString(), subtitleDisabled: state.subtitleDisabled.toString(), useBG: state.useBG.toString(), scaledHeight: state.scaledHeight.toString(),
                    wavePosition: state.wavePosition.toString(), isSquare: state.isSquare.toString(), isLoading: state.isLoading.toString(), currentIsMonoBuffer: state.currentIsMonoBuffer.toString(),
                    exportingCanvas: state.exportingCanvas.toString(), currentAudio: state.currentAudio.toString()
                };

                state.params = params;

            },

            toggleExport(state) {
                state.exportingCanvas = !state.exportingCanvas
            },

            setLoading(state, { payload }) {
                state.isLoading = payload
            },
            setTemplateSelected(state, { payload }) {
                state.templateSelected = payload
                //   if(!payload) state.isLoading=true
            },
            initTemplate(state, { payload }) {
                state.template = payload
                switch (payload) {
                    case ILOVEYOU.template:
                        state.soundwaveType = SOUNDWAVETYPE.NORMAL
                        state.landscape = ILOVEYOU.landscape
                        state.drawMode = ILOVEYOU.drawMode
                        state.lineWidth = ILOVEYOU.lineWidth
                        state.lineHeight = ILOVEYOU.lineHeight
                        state.sampleSize = ILOVEYOU.sampleSize
                        state.scaledHeight = ILOVEYOU.scaledHeight
                        state.landscape = ILOVEYOU.landscape
                        state.currentAudio = ILOVEYOU.audio
                        state.subtitleDisabled = false;
                        state.isSquare = false;
                        state.textInWave = false
                        state.useBG = false;


                        break;
                    case LINETEMPLATE.template:
                        state.soundwaveType = SOUNDWAVETYPE.NORMAL
                        state.landscape = LINETEMPLATE.landscape
                        state.drawMode = LINETEMPLATE.drawMode
                        state.lineWidth = LINETEMPLATE.lineWidth
                        state.lineHeight = LINETEMPLATE.lineHeight
                        state.sampleSize = LINETEMPLATE.sampleSize
                        state.scaledHeight = LINETEMPLATE.scaledHeight
                        state.landscape = LINETEMPLATE.landscape
                        state.currentAudio = LINETEMPLATE.audio
                        state.subtitleDisabled = false;
                        state.useBG = false;
                        state.isSquare = false;
                        state.textInWave = false
                        break;
                    case LINEARTEMPLATE.template:
                        state.soundwaveType = SOUNDWAVETYPE.LINEAR
                        state.landscape = LINEARTEMPLATE.landscape
                        state.drawMode = LINEARTEMPLATE.drawMode
                        state.barWidth = LINEARTEMPLATE.barWidth
                        state.barHeight = LINEARTEMPLATE.barHeight
                        state.sampleSize = LINEARTEMPLATE.sampleSize
                        state.scaledHeight = LINEARTEMPLATE.scaledHeight
                        state.landscape = LINEARTEMPLATE.landscape
                        state.currentAudio = LINEARTEMPLATE.audio
                        state.subtitleDisabled = false;
                        state.textInWave = false
                        state.useBG = false;
                        state.isSquare = false;
                        break;
                    case RADIAL.template:
                        state.soundwaveType = SOUNDWAVETYPE.RADIAL
                        state.landscape = RADIAL.landscape
                        state.drawMode = RADIAL.drawMode
                        state.lineWidth = RADIAL.lineWidth
                        state.lineHeight = RADIAL.lineHeight
                        state.radius = RADIAL.radius
                        state.sampleSize = RADIAL.sampleSize
                        state.scaledHeight = RADIAL.scaledHeight
                        state.landscape = RADIAL.landscape
                        state.currentAudio = RADIAL.audio
                        state.subtitleDisabled = false;
                        state.textInWave = false
                        state.useBG = false;
                        state.isSquare = false;
                        break;
                    case RADIALCIRCLE.template:

                        state.soundwaveType = SOUNDWAVETYPE.RADIAL
                        state.landscape = RADIALCIRCLE.landscape
                        state.drawMode = RADIALCIRCLE.drawMode
                        state.lineWidth = RADIALCIRCLE.lineWidth
                        state.lineHeight = RADIALCIRCLE.lineHeight
                        state.radius = RADIALCIRCLE.radius
                        state.sampleSize = RADIALCIRCLE.sampleSize
                        state.scaledHeight = RADIALCIRCLE.scaledHeight
                        state.landscape = RADIALCIRCLE.landscape
                        state.currentAudio = RADIALCIRCLE.audio
                        state.subtitleDisabled = false;
                        state.textInWave = false
                        state.useBG = false;
                        state.isSquare = false;
                        break;

                    case IMAGEBACKGROUND.template:
                        state.soundwaveType = SOUNDWAVETYPE.BGIMAGE
                        state.landscape = IMAGEBACKGROUND.landscape
                        state.drawMode = IMAGEBACKGROUND.drawMode
                        state.lineWidth = IMAGEBACKGROUND.lineWidth
                        state.lineHeight = IMAGEBACKGROUND.lineHeight
                        state.sampleSize = IMAGEBACKGROUND.sampleSize
                        state.scaledHeight = IMAGEBACKGROUND.scaledHeight
                        state.currentAudio = IMAGEBACKGROUND.audio
                        state.useBG = true
                        state.textInWave = true
                        state.subtitleDisabled = true
                        state.offsetX = IMAGEBACKGROUND.offsetX
                        state.offsetY = IMAGEBACKGROUND.offsetY
                        break;

                    case HEARTTEMPLATE.template:
                        state.soundwaveType = SOUNDWAVETYPE.HEART
                        state.landscape = HEARTTEMPLATE.landscape
                        state.currentAudio = IMAGEBACKGROUND.audio
                        state.drawMode = HEARTTEMPLATE.drawMode
                        state.lineWidth = HEARTTEMPLATE.lineWidth
                        state.lineHeight = HEARTTEMPLATE.lineHeight
                        state.sampleSize = HEARTTEMPLATE.sampleSize
                        state.scaledHeight = HEARTTEMPLATE.scaledHeight
                        state.useBG = HEARTTEMPLATE.useBG
                        state.textInWave = HEARTTEMPLATE.textinWave
                        state.wavePosition = HEARTTEMPLATE.wavePosition
                        state.radius = HEARTTEMPLATE.radius
                        state.subtitleDisabled = false
                        state.offsetX = HEARTTEMPLATE.offsetX
                        state.offsetY = HEARTTEMPLATE.offsetY
                        state.heartPosition = HEARTTEMPLATE.heartPosition
                        state.bgImage = wedding
                        state.heartTemplate = true;
                      
                        break;

                    case IMAGEINWAVE.template:
                        state.soundwaveType = SOUNDWAVETYPE.PICTURE
                        state.audio = IMAGEINWAVE.audio
                        state.landscape = IMAGEINWAVE.landscape
                        state.drawMode = IMAGEINWAVE.drawMode
                        state.lineWidth = IMAGEINWAVE.lineWidth
                        state.lineHeight = IMAGEINWAVE.lineHeight
                        state.sampleSize = IMAGEINWAVE.sampleSize
                        state.scaledHeight = IMAGEINWAVE.scaledHeight
                        state.currentAudio = IMAGEINWAVE.audio
                        state.textInWave = false
                        state.useBG = false;
                        state.subtitleDisabled = false
                        state.offsetX = IMAGEINWAVE.offsetX
                        state.offsetY = IMAGEINWAVE.offsetY
                        state.imageHorizontal = IMAGEINWAVE.imageHorizontal;
                        state.imageVertical = IMAGEINWAVE.imageVertical;

                        break;

                    default:
                        console.error("INVALID TEMPLATE");
                        break;

                }
                state.orientation = state.isSquare ? ORIENTATION.SQUARE : state.landscape ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT;
            },

            clearCanvas(state, { payload }) {
                state.clearCanvas = true
            },

            getState(state) {
                return state;
            },


            changeSoundwaveType(state, { payload }) {
                state.soundwaveType = payload
            },
            changeOrientation(state, { payload }) {
                state.orientation = payload
                if (payload === ORIENTATION.SQUARE) { state.isSquare = true; state.landscape = false }
                else if (payload === ORIENTATION.LANDSCAPE) { state.landscape = true; state.isSquare = false }
                else {
                    state.landscape = false
                    state.isSquare = false
                }

            },
            changeDrawMode(state, { payload }) {
                state.drawMode = payload
            },
            changeWaveHeight(state, { payload }) {
                state.waveHeight = payload
            },
            changePaddingWidth(state, { payload }) {
                state.paddingWidth = payload
            },
            changeLineHeight(state, { payload }) {
                state.lineHeight = payload
            },
            changeLineWidth(state, { payload }) {
                state.lineWidth = payload
            },
            changeHeartPosition (state, {payload}){
                state.heartPosition = payload
            },
            changeWavePosition(state, { payload }) {
                state.wavePosition = payload
            },
            changeBarHeight(state, { payload }) {
                state.barHeight = payload
            },
            changeBarWidth(state, { payload }) {
                state.barWidth = payload
            },
            changeRadius(state, { payload }) {
                state.radius = payload
            },
            changeSpacing(state, { payload }) {
                state.spacing = payload
            },
            changeSampleSize(state, { payload }) {
                state.sampleSize = payload
            },
            changeWaveImage(state, { payload }) {
                state.waveImage = payload
            },
            changeImageHorizontal(state, { payload }) {
                state.imageHorizontal = payload
            },
            changeImageVertical(state, { payload }) {
                state.imageVertical = payload
            },
            changeBgImage(state, { payload }) {
                state.bgImage = payload
            },
            toggleTextInWave(state) {
                state.textInWave = !state.textInWave
            },

            toggleMonoBuffer(state) {
                state.currentIsMonoBuffer = !state.currentIsMonoBuffer
            },
            changeScaledHeight(state, { payload }) {
                state.scaledHeight = payload
            },
            changeMedium(state, { payload }) {
                state.medium = payload
            },
            changeSize(state, { payload }) {
                state.size = payload
            },

            toggleIsSquare(state, payload) {
                state.isSquare = payload
            },
            usingBG(state, payload) {
                state.useBG = payload
            },
            uploadWaveImage(state, payload) {
                state.waveImage = payload
            },
            uploadBGImage(state, payload) {
                state.bgImage = payload
            },
            setParams(state, { payload }) {
                state.params = payload;
            },
            changeOffsetX(state, { payload }) {
                state.offsetX = payload;
            },

            changeOffsetY(state, { payload }) {
                state.offsetY = payload;
            },


        },

    }
)

export const { clearCanvas, uploadWaveImage, uploadBGImage, prepareCheckout, changeBuffer, changeUnsampledBuffer, setLoading, setTemplateSelected, changeBgImage, changeDrawMode, changeLineHeight, changeMedium,
    changeOrientation, changePaddingWidth, changeRadius, changeSampleSize, changeSize, changeOffsetY, changeOffsetX, changeImageHorizontal, changeImageVertical,
    changeScaledHeight, changeSoundwaveType, toggleExport, changeSpacing, initTemplate, getState,
    changeWaveHeight, changeWaveImage, setParams, toggleIsSquare, toggleSubtitleDisabled, currentAudio, changeHeartPosition,
    toggleTextInWave, changeLineWidth, heartTemplate, changeBarHeight, changeBarWidth, changeWavePosition } = soundArtSlice.actions

export default soundArtSlice.reducer