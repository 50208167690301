import {configureStore} from "@reduxjs/toolkit"
import textReducer from "./features/TextFeature/textFeatureSlice"
import colorReducer from "./features/ColorFeature/colorFeatureSlice"
import soundArtReducer from "./features/SoundArtFeature/soundArtSlice"

 
const store = configureStore({
    reducer:{
        textReducer,colorReducer,soundArtReducer
        
    },
})
export default store