import React from "react";
import "./css/base.css";
import "./css/template-grid.css";
import "../../../App.css"
import iloveyou from "../../../assets/iloveyoutemplate2.jpg";
import alternativeRadial from "../../../assets/gradRadial.png";
import weddingwavehtemp from "../../../assets/weddingh1_spaced.jpg";
import weddingbgtemp from "../../../assets/fatherdaughter.jpg";
import linetemplate from "../../../assets/linetemplate.jpg";
import bartemplate from "../../../assets/bartemplate.jpg";
import radialcircle from "../../../assets/radialcircle.png"
import hearttemplate from "../../../assets/hearttemplate.jpg"
import { useDispatch } from "react-redux"
import { initTemplate, setTemplateSelected, setLoading } from "../../../features/SoundArtFeature/soundArtSlice.jsx"
import { initText } from "../../../features/TextFeature/textFeatureSlice.jsx"
import { initColors } from "../../../features/ColorFeature/colorFeatureSlice.jsx"
import { ILOVEYOU, RADIAL, LINETEMPLATE, LINEARTEMPLATE, IMAGEBACKGROUND, IMAGEINWAVE } from "../TemplatingComponents/Templates.jsx"
export default function TemplateSelectComponent(props) {
  const dispatch = useDispatch();

  const templates = [
    {
      name: "iloveyou",
      tags: ["Valentines Day"],
      image: iloveyou,
      soundwaveType: "normal",
      initialAudio: ILOVEYOU.audio,
      aria: "Valentines Day Template. Template showing a white mono colored sound wave with a title of I love you on a red background.",
    },
    {
      name: "weddingfullh",
      tags: ["Wedding", "Photo"],
      image: weddingbgtemp,
      soundwaveType: "bg-picture",
      initialAudio: IMAGEBACKGROUND.audio,
      aria: "Soundwave with Background Image Template. Template showing a gradient colored sound wave with a title of World's best dad! This template allows your own photo to be used as a background photo whilst the soundwave is in the foreground. .",
    },

    {
      name: "weddingwavehtemp",
      tags: ["Wedding", "Photo"],
      image: weddingwavehtemp,
      soundwaveType: "picture",
      initialAudio: IMAGEINWAVE.audio,
      aria: "Image in Wave Template. Template showing a sound wave with an image inside on white background with the title Emma and James and a subtitle 10.08.2019. This template allows you to upload your own image which will appear inside the soundwave itself..",
    },

    {
      name: "hearttemplate",
      tags: ["Wedding", "Photo"],
      image: hearttemplate,
      soundwaveType: "picture",
      initialAudio: IMAGEBACKGROUND.audio,
      aria: "Image in Wave Template. Template showing a sound wave with an image inside on white background with the title Emma and James and a subtitle 10.08.2019. This template allows you to upload your own image which will appear inside the soundwave itself..",
    },
    {
      name: "radialcleanwide",
      tags: ["Baby", "Radial"],
      image: alternativeRadial,
      soundwaveType: "radial",
      initialAudio: RADIAL.audio,
      aria: "Radial Template. Template showing a multi colored sound wave visualized in a radial shape on a white background. Showing a title of Your First Ultrasound and a subtitle"
    },
    /*
      name: "radialcircle",
      tags: ["Baby", "Radial"],
      image: radialcircle,
      soundwaveType: "radial",
      initialAudio: RADIAL.audio,
      aria: "Radial Circle Template. Template showing a multi colored sound wave visualized in a radial shape on a white background. Showing a title of Your First Ultrasound and a subtitle"
    },
*/
    {
      name: "cleanlinetemplate",
      tags: ["Baby", "line"],
      image: linetemplate,
      soundwaveType: "normal",
      initialAudio: LINETEMPLATE.audio,
      aria: "Line Template. Template showing a gradient colored sound wave with a title of Bliss - Muse on a white background.",
    },
    {
      name: "cleanbartemplate",
      tags: ["Baby", "linear"],
      image: bartemplate,
      soundwaveType: "normal",
      initialAudio: LINEARTEMPLATE.audio,
      aria: "Linear Template. Template showing a sound wave visualized as a bar chart on a white background. With a title of Bar Chart Template",
    },
  ];

  const selected = (template) => {
    dispatch(setLoading(true))
    dispatch(initColors(template.name))
    dispatch(initText(template.name))
    dispatch(initTemplate(template.name))
    dispatch(setTemplateSelected(true))
    const { history } = props;
    history.push("/design");

  }


  const titleStyle = {
    color: "#24252b",
    fontSize: props.mobile ? "1.25rem" : "2rem",
   padding:"1.33rem",
    fontFamily: "Dancing Script",
    fontWeight: "800",
    boxSizing:"border-box"
  };
  const templatesToRender = templates.map((el) => (
    <button
      onClick={() => selected(el)}
      key={el.name}
      aria-label={el.aria}
      className="card cardShadow card-wide"
      style={{
        backgroundImage: `url(${el.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        border: "none",
        zIndex: 0,
        cursor: "pointer"
      }}
    >
    </button>
  ));
  return (
    <section className="templateSelect" aria-label="templates">

      <h1 style={titleStyle}>Select a Template to Start Designing</h1>
      <div className="template-grid">
        {
          templatesToRender
        }
      </div>

    </section>
  );

}
