import React from "react";
import Cart from "./Cart";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import logo from "../../assets/logo.svg"
import "./styling.css";
import DesignProduct from "./DesignProduct";
import QRCode from "../../assets/qr.png";
import FooterComponent from "../FooterComponent";




export default function CartPage(props) {



  const editDesign = (paramString) => {
    console.log("parameter string: ");
    console.log(paramString);
    props.history.push(paramString);
  }

  const addNewDesign = () => {
    props.history.push("/");
  }


  window.scrollTo(0, 0);
  let qrStyle = {
    display: "flex",

    width: "100%",
  };

  let designs;
  let canvasContainerStyle = {
    //      background: this.state.bgColor, WAIT UNTIL LAYERED COMPOSITING IS DONE

    position: "relative",
    width: "300px",
    height: "225px",
    border: "none",

    marginTop: "2em",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    boxShadow:
      "0px 5px 22px 3px rgba(0,0,0,0.10)",
  };
  const canvasPortraitStyle = {
    position: "relative",
    width: "225px",
    height: "300px",
    border: "none",

    marginTop: "2em",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    boxShadow:
      "0px 5px 22px 3px rgba(0,0,0,0.10)",
  };

  const canvasSquareStyle = {
    position: "relative",
    width: "225px",
    height: "225px",
    border: "none",
    marginTop: "2em",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    boxShadow:
      "0px 5px 22px 3px rgba(0,0,0,0.15)",
  };


  if (props.designs) {


    console.log(props);
    console.log(designs);
    designs = props.designs.map((el, index) => (

      <DesignProduct
        className="designProduct"
        editDesign={() => editDesign(el.paramString)
        }
        removeDesign={() => props.removeDesign(index)
        }
        style={el.orientation === "Square" ? canvasSquareStyle : el.orientation === "Portrait" ? canvasPortraitStyle : canvasContainerStyle}
        image={el.image}
        key={index}
        size={el.size}
        medium={el.medium}
        orientation={el.orientation}
      />
    ));
  }
  const qrCodeImg = QRCode;

  return (
    <div
      className={designs.length > 0 ? "cartPageGrid" : "emptyCartGrid"}
    >
      <div
        className={designs.length > 0 ? "cartLeftGrid" : "emptyCartGrid"}
        style={{
          backgroundColor: "white",

          margin: "0 auto",
          width: "100%",
          height: "100%",
          minHeight: "100vh"
        }}
      >
        <img src={logo} alt="your special sound logo" style={{ height: "3em", margin: "2em" }}></img>
        {designs.length > 0 ? (
          <div className="user-designs">{designs}</div>
        ) : (
          <div
            className="emptyCart"
          >
            <h1 style={{ fontFamily: "Raleway", color: "black" }}>Your cart is empty</h1>
            <p style={{ paddingBottom: "0.5em", color: "#24252b" }}>Click the button below to create your unique design.</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addNewDesign()}
              style={{
                margin: "0 auto",
                marginBottom: "4em",
              }}
            >
              Create Your Special Sound
            </Button>
          </div>
        )}

        {designs.length > 0 ? (
          <div className="addanother">

            <Button

              variant="contained"
              color="primary"
              size="large"
              onClick={() => addNewDesign()}
              style={{
                marginLeft: "2em",
                marginBottom: "1em",

              }}
            >
              + Add Another Design
            </Button>
            {designs.length > 1 ? null :
              <span
                className="discount"
                style={{

                  gridArea: "discount",
                  color: "#5f00ba",
                  marginLeft: "1em",
                  marginRight: "1em",
                  paddingRight: "1em",
                  paddingTop: "1.2em",
                  fontWeight: "bold",
                }}
              >
                Get 15% Off On Your Second Special Sound!
              </span>}

          </div>
        ) : null}

        {designs.length > 0 ? (
          <div className="qrcode" style={{ qrStyle }}>
            <img
              alt="Custom QR Code"
              style={{

                height: "100px",
                width: "100px",
                marginLeft: "2em",
                paddingTop: "2em",
              }}
              src={qrCodeImg}
            ></img>
            <span
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyItems: "center",
                color: "Black",
                marginLeft: "2em",
                marginRight: "1em",
                fontWeight: "bold"
              }}
            >
              Listen to Your Special Sound at any time by scanning the QR code
              that comes bundled with your design with your phone!
            </span>
          </div>
        ) : null}


        {(
          <footer
            style={{
              gridArea: "footer",
              position: "relative",
              bottom: "0",
              display: "flex",
              color: "#5f00ba",
              backgroundColor: "white",
              paddingTop: "1em",
              justifyContent: "center",
              alignSelf: "end",
              width: "100%"
            }}
          >
            <Divider style={{ marginBottom: "2em" }} />
            <span style={{ fontSize: "10pt" }}>© Your Special Sound LLC</span>
            <a href="∞"
              style={{
                fontSize: "0.75em",
                color: "#5f00ba",
                paddingLeft: "2em",

              }}
            >
              Privacy Policy
            </a>
            <a href="∞"
              style={{
                fontSize: "0.75em",
                color: "#5f00ba",
                paddingLeft: "2em",

              }}
            >
              Returns & Refund Policy
            </a>
            <a href="∞"
              style={{
                color: "#5f00ba",
                fontSize: "0.75em",
                paddingLeft: "2em",
              }}
            >

              Terms of Service
            </a>
            <a href="∞"
              style={{
                fontSize: "0.75em",
                color: "#5f00ba",
                paddingLeft: "2em",
              }}
            >
              Shipping Policy
            </a>
            <a href="∞"
              style={{
                fontSize: "0.75em",
                color: "#5f00ba",
                paddingLeft: "2em"
              }}
            >
              Accessibility Statement
            </a>

          </footer>
        )}
      </div>
      {designs.length > 0 ? (
        <div>

          <Cart
            updateQuantityInCart={props.updateQuantityInCart}
            removeLineItemInCart={props.removeLineItemInCart}
            addVariantToCart={props.addVariantToCart}
            checkout={props.checkout}
            storage={props.storage}
            cartOpen={true}
            designs={props.designs}
            products={props.products}
          />

        </div>
      ) : designs.length > 0 ? <FooterComponent /> : null
      }
    </div>
  );
}
