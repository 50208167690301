import {createSlice} from "@reduxjs/toolkit";
import { ILOVEYOU, IMAGEBACKGROUND, RADIAL, IMAGEINWAVE, LINEARTEMPLATE, LINETEMPLATE, HEARTTEMPLATE } from "../../Components/SidebarComponents/TemplatingComponents/Templates";

const editTextSlice = createSlice({
    name:"editText",
    initialState:{
        title:"Emma & James",
        subtitle:"10.08.2019",
        titleSize:34,
        subtitleSize:20,
        titleFont:"Dancing Script",
        subtitleFont:"Dancing Script",
        titleWeight:false,
        subtitleWeight:false,
        titleStyle:false,
        subtitleStyle:false,
        titleAlign:"center",
        subtitleAlign:"center",
        titleVerticalAlign:"bottom",
        subtitleVerticalAlign:"bottom"
        
    },
    reducers:{

        initText(state,{payload}){
            state.template = payload
            switch (payload) {
                case "iloveyou":
                    state.title = ILOVEYOU.title
                    state.subtitle = ILOVEYOU.subtitle
                    state.titleSize = ILOVEYOU.titleSize
                    state.subtitleSize = ILOVEYOU.subtitleSize
                    state.titleFont = ILOVEYOU.titleFont
                    state.subtitleFont = ILOVEYOU.subtitleFont
                    state.titleAlign = ILOVEYOU.titleAlign
                    state.subtitleAlign = ILOVEYOU.subtitleAlign
                    state.titleWeight = ILOVEYOU.titleWeight
                    state.subtitleWeight = ILOVEYOU.subtitleWeight
                    

                    break;
                case "cleanlinetemplate":
                    state.title = LINETEMPLATE.title
                    state.subtitle = LINETEMPLATE.subtitle
                    state.titleSize = LINETEMPLATE.titleSize
                    state.subtitleSize = LINETEMPLATE.subtitleSize
                    state.titleFont = LINETEMPLATE.titleFont
                    state.subtitleFont = LINETEMPLATE.subtitleFont
                    state.titleAlign = LINETEMPLATE.titleAlign
                    state.subtitleAlign = LINETEMPLATE.subtitleAlign
                    state.titleWeight = LINETEMPLATE.titleWeight
                    state.subtitleWeight = LINETEMPLATE.subtitleWeight
                    break;
                case "cleanbartemplate":
                    state.title = LINEARTEMPLATE.title
                    state.subtitle = LINEARTEMPLATE.subtitle
                    state.titleSize = LINEARTEMPLATE.titleSize
                    state.subtitleSize = LINEARTEMPLATE.subtitleSize
                    state.titleFont = LINEARTEMPLATE.titleFont
                    state.subtitleFont = LINEARTEMPLATE.subtitleFont
                    state.titleAlign = LINEARTEMPLATE.titleAlign
                    state.subtitleAlign = LINEARTEMPLATE.subtitleAlign
                    state.titleWeight = LINEARTEMPLATE.titleWeight
                    state.subtitleWeight = LINEARTEMPLATE.subtitleWeight
                    break;
                case "radialcleanwide":
                    state.title = RADIAL.title
                    state.subtitle = RADIAL.subtitle
                    state.titleSize = RADIAL.titleSize
                    state.subtitleSize = RADIAL.subtitleSize
                    state.titleFont = RADIAL.titleFont
                    state.subtitleFont = RADIAL.subtitleFont
                    state.titleAlign = RADIAL.titleAlign
                    state.subtitleAlign = RADIAL.subtitleAlign
                    state.titleWeight = RADIAL.titleWeight
                    state.subtitleWeight = RADIAL.subtitleWeight
                break;

                case "weddingfullh":
                    state.title = IMAGEBACKGROUND.title
                    state.subtitle = IMAGEBACKGROUND.subtitle
                    state.titleSize = IMAGEBACKGROUND.titleSize
                    state.subtitleSize = IMAGEBACKGROUND.subtitleSize
                    state.titleFont = IMAGEBACKGROUND.titleFont
                    state.subtitleFont = IMAGEBACKGROUND.subtitleFont
                    state.titleAlign = IMAGEBACKGROUND.titleAlign
                    state.subtitleAlign = IMAGEBACKGROUND.subtitleAlign
                    state.titleWeight = IMAGEBACKGROUND.titleWeight
                    state.subtitleWeight = IMAGEBACKGROUND.subtitleWeight
                break;
                case "hearttemplate":
                    state.title = HEARTTEMPLATE.title
                    state.subtitle = HEARTTEMPLATE.subtitle
                    state.titleSize = HEARTTEMPLATE.titleSize
                    state.subtitleSize = HEARTTEMPLATE.subtitleSize
                    state.titleFont = HEARTTEMPLATE.titleFont
                    state.subtitleFont = HEARTTEMPLATE.subtitleFont
                    state.titleAlign = HEARTTEMPLATE.titleAlign
                    state.subtitleAlign = HEARTTEMPLATE.subtitleAlign
                    state.titleWeight = HEARTTEMPLATE.titleWeight
                    state.subtitleWeight = HEARTTEMPLATE.subtitleWeight
                break;
                case "weddingwavehtemp":
                    state.title = IMAGEINWAVE.title
                    state.subtitle = IMAGEINWAVE.subtitle
                    state.titleSize = IMAGEINWAVE.titleSize
                    state.subtitleSize = IMAGEINWAVE.subtitleSize
                    state.titleFont = IMAGEINWAVE.titleFont
                    state.subtitleFont = IMAGEINWAVE.subtitleFont
                    state.titleAlign = IMAGEINWAVE.titleAlign
                    state.subtitleAlign = IMAGEINWAVE.subtitleAlign
                    state.titleWeight = IMAGEINWAVE.titleWeight
                    state.subtitleWeight = IMAGEINWAVE.subtitleWeight

                break;
            
                default:

                    break;
                  
            }
      
        },

        changeTitle(state,action){
            state.title = action.payload
            //encodeURIComponent(event.target.value);
        },
        changeSubtitle(state,action){
            state.subtitle = action.payload
        },
        changeTitleSize(state,action){
            state.titleSize = action.payload
        },
        changeSubtitleSize(state,action){
            state.subtitleSize = action.payload
        },
        changeTitleFont(state,action){
            state.titleFont = action.payload
        },
        changeSubtitleFont(state,action){
            state.subtitleFont = action.payload
        },
        toggleTitleWeight(state){
            state.titleWeight = !state.titleWeight
        },
        toggleSubtitleWeight(state){
            state.subtitleWeight = !state.subtitleWeight
        },
        toggleTitleStyle(state){
            state.titleStyle = !state.titleStyle
        },
        toggleSubtitleStyle(state){
            state.subtitleStyle = !state.subtitleStyle
        },
        changeTitleAlign(state,action){
            state.titleAlign = action.payload
        },
        changeSubtitleAlign(state,action){
            state.subtitleAlign = action.payload
        },
        changeTitleVerticalAlign(state,action){
            state.titleVerticalAlign = action.payload
        },
        changesubtitleVerticalAlign(state,action){
            state.subtitleVerticalAlign = action.payload
        },
        
    }
    
})

export const {initText,changeTitle,changeSubtitle,subtitleVerticalAlign,titleVerticalAlign,
    changeTitleSize,changeSubtitleSize,changeTitleFont, 
    changeSubtitleFont,changeSubtitleAlign,toggleSubtitleWeight,
    changeTitleAlign,toggleTitleWeight,toggleSubtitleStyle,toggleTitleStyle} = editTextSlice.actions
export default editTextSlice.reducer