import React from 'react'
import "./shippingThresholdComponent.css"
import LinearProgress from '@material-ui/core/LinearProgress';
import Products from './Products';
export default function ShippingThresholdComponent(props) {
console.log("products passed into props: ");
console.log (props.products);
    if(props.amountToReach === 0){
        return (
            <div className="threshcontainer">
                <div className="innerWrapper">
                <p style={{paddingTop:"0", fontWeight:"bold"}}>You did it! Shipping is on us! <span role="img" alt="party popper emoji" aria-label="you are eligible for free shipping on your order">🎉</span></p>
                </div>
                
            </div>
        )
    }

    return (
        <div className="threshcontainer">
            <div className="innerWrapper">
            <p style={{paddingTop:"0"}}>You are just <span style={{color:"#5f00ba", fontWeight:"bold"}}>${props.amountToReach.amountLeft}</span> away from getting <span className="freeshipping">Free Shipping</span> on your order! {" "} 😀</p>
            <LinearProgress variant="determinate" value={props.amountToReach.percentage} />
            <p style={{fontWeight:"bold"}}>This will help you get there:</p>
            <Products shippingThreshold addVariantToCart={props.addVariantToCart} products={props.products} />
            </div>
            
        </div>
    )
}
///         <Products products={props.products} />