import { Button } from "@material-ui/core";
import React, { Component } from "react";
import LineItem from "./LineItem";
import ShippingThresholdComponent from "./ShippingThresholdComponent"
import LockIcon from '@material-ui/icons/Lock';
class Cart extends Component {
  constructor(props) {
    super(props);

    this.openCheckout = this.openCheckout.bind(this);
  }

  openCheckout() {
   
this.props.designs.forEach(design =>{ 

  let url = design.image;

  fetch(url)
  .then(res => res.blob())
  .then(res => {
    console.log(res);
    const storageRef = this.props.storage.ref();
    const fileRef = storageRef.child(`checkouts/${this.props.checkout.id}/${design.uid}/clientDesign.png`);
    
  fileRef.put(res);
  })
  console.log(design.waveImage);
  if(design.waveImage){
    let originalImage = design.waveImage;
  
  fetch(originalImage)
  .then(res => res.blob())
  .then(res => {
    console.log(res);
    const storageRef = this.props.storage.ref();
    const fileRef = storageRef.child(`checkouts/${this.props.checkout.id}/${design.uid}/originalImage.png`);
    
  fileRef.put(res);
  })
  }
    const jsonString = JSON.stringify(design.data);
    const blob = new Blob([jsonString], {type: "application/json"})
    const storageRef = this.props.storage.ref();
    const fileRef = storageRef.child(`checkouts/${this.props.checkout.id}/${design.uid}/data.json`);
  
  fileRef.put(blob);
  

}) 



  window.open(this.props.checkout.webUrl);
  }
   calculateShippingThreshold(){
    const currentTotal = this.props.checkout.totalPrice
    const currentGoal = 153;
    const progress = (currentTotal / currentGoal) * 100;
    if (progress > 100) return 0
    
    let thresholdInfo={
      percentage:progress, amountLeft: currentGoal - currentTotal
    };
     return thresholdInfo
    


  }
  
  render() {
    let line_items = this.props.checkout.lineItems.map((line_item, index) => {
      return (
        <LineItem
          updateQuantityInCart={this.props.updateQuantityInCart}
          removeLineItemInCart={this.props.removeLineItemInCart}
          discount={
            this.props.checkout.discountApplications[index]
              ? this.props.checkout.discountApplications[index]
              : null
          }
          key={line_item.id.toString()}
          line_item={line_item}
        />
      );
    });
    if (this.props.cartOpen) {
      return (
        <div
          className={
            this.props.mobile
              ? "CartMobile"
              : `Cart ${this.props.isCartOpen ? "Cart--open" : ""}`
          }
        >
          <header className="Cart__header">
          <ShippingThresholdComponent addVariantToCart={this.props.addVariantToCart} amountToReach={this.calculateShippingThreshold()} products={this.props.products} />
            <h2>Order Summary</h2>
          </header>
          <ul className="Cart__line-items">{line_items}</ul>
          <footer className="Cart__footer">
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Subtotal</div>
              <div className="Cart-info__pricing">
                <span className="pricing">
                  $ {this.props.checkout.subtotalPrice}
                </span>
              </div>
            </div>
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Taxes</div>
              <div className="Cart-info__pricing">
                <span className="pricing">
                  $ {this.props.checkout.totalTax}
                </span>
              </div>
            </div>
            <div className="Cart-info clearfix">
              <div className="Cart-info__total Cart-info__small">Total</div>
              <div className="Cart-info__pricing">
                <span className="pricing">
                  $ {this.props.checkout.totalPrice}
                </span>
              </div>
            </div>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<LockIcon />}
              size="large"
              style={{marginTop:"2em", width:"100%"}}
              onClick={this.openCheckout}
            >
              Proceed to Checkout
            </Button>
          </footer>
        </div>
      );
    }
    return <div></div>;
  }
}

export default Cart;
