import React from "react";
import "./ColorPaletteComponent.css";
import ColorSwatchComponent from "./ColorSwatchComponent.jsx";


export default  function ColorPaletteComponent (props)  {

    const myColors = props.colors
      ? props.colors.map((el, index) => (
          <ColorSwatchComponent color={el} key={index} />
        ))
      : null;

    return (
      <div  id="wrapper">
        <div id="insideWrapper">
          <div id="swatchContainer">{myColors}</div>
        </div>
      </div>
    );
  
}
