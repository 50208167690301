import React, { Component } from "react";
import Product from "./Product";
import "./styling.css";

class Products  extends Component  {
  render() {
    console.log(this.props);
    console.log(this.props.products);

    this.props.products[0].map((product, index) =>{
      console.log((product));
    })

    return (
      <div
        className={
          this.props.mobile ? "Product-wrapper-mobile" :this.props.shippingThreshold?"Product-wrapper-threshold": "Product-wrapper"
        }
      >
      
        {this.props.products[0].map((product, index) =>{
        return(

          <div key={index} style={{textAlign:"left" }}>
          <Product
            addVariantToCart={this.props.addVariantToCart}
            shippingThreshold= {this.props.shippingThreshold}
            client={this.props.client}
            key={index}
            product={product}
          />
        </div>
        );

        })}
      </div>
    );
  }
}

export default Products;
