import React from "react";
import { Slider } from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux"
import {changeSampleSize,changeBarHeight,changeBarWidth} from "../../../features/SoundArtFeature/soundArtSlice"

export default function LinearConfigComponent () {

const dispatch = useDispatch();
  const barWidth = useSelector(state => state.soundArtReducer.barWidth)
  const barHeight = useSelector(state => state.soundArtReducer.barHeight)
  const sampleSize = useSelector(state => state.soundArtReducer.sampleSize)



    const sliderStyle = {
      marginLeft: "2em",
      paddingRight: "2em",
      root: {
        color: "#5f00ba",
      },
    };
    const containerStyle = {
      boxSizing: " border-box",
      display: "flex",
      flexDirection: "column",
      lineHeight: "1",
      fontFamily: "inherit",
      width: "100%",
      padding: "10px 15px",
      backgroundColor: "#fff",
 
    };
    return (
      <div style={containerStyle}>
        <p style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
          Bar Thickness:
        </p>
       
        <Slider
          value={barWidth}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          step={0.01}
          min={0}
          max={60}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeBarWidth(newValue))}}
          style={{ sliderStyle }}
        />
        <p style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
          Bar Height:
        </p>
      
        <Slider
          value={barHeight}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={300}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeBarHeight(newValue))}}
          style={{ sliderStyle }}
        />

        <p style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
          Complexity (Number of bars):
        </p>
      
        <Slider
      
          value={sampleSize}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          step={1}
          min={1}
          max={70}
          onChange={(event,newValue) => {event.preventDefault(); dispatch(changeSampleSize(newValue))}}
          style={{ sliderStyle }}
        />
       
      </div>
    );
  
}
