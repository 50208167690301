import React, { useState } from "react";
import { Button, Modal } from "@material-ui/core";
import MicRoundedIcon from "@material-ui/icons/MicRounded";
import Recorder from "recorder-js";
import RecIcon from "@material-ui/icons/FiberManualRecord";
import StopIcon from "@material-ui/icons/Stop";
import MicWarning from "../../assets/micWarning.jpg";
export default function ModaleRecordComponent(props) {

    const [open, setOpen] = useState(false)
    const [stream, setStream] = useState(null)
    const [recording, setRecording] = useState(false)
    const [recorder, setRecorder] = useState(null)

    const desktopStyle = {
      backgroundColor: "white",
      width: "50%",
      alignItems: "center",
      position: "absolute",
      color: "black",
      left: "25%",
      top: "10%",
    };
    const mobileStyle = {
      // backgroundColor: "#5f00ba",
      backgroundColor: "#fff",
      width: "100%",
      color: "black",
      alignItems: "center",
      position: "relative",
      left: "0%",
      top: "12.5%",
    };

    const body = (
      <div style={desktopStyle}>
        <h2
          style={{
            paddingTop: "0",
            textAlign: "center",
          }}
        >
          Record Sound Wave
        </h2>
        <div style={{ paddingLeft: "2em", display: "block" }}>
          {props.mobile ? null : <img src={MicWarning}></img>}
        </div>
        {props.mobile ? (
          <p
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
              display: "block",
            }}
          >
            To record a soundwave you must consent to the use of your
            microphone.
          </p>
        ) : (
          <p
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
              display: "block",
            }}
          >
            In order to record a sound wave we need access to your microphone. If
            you see a warning like the one above right now, make sure the
            correct microphone is selected and click{" "}
            <span style={{ fontWeight: "bold", display: "inline" }}>Allow</span>
            . Depending on which Web Browser or language you are using yours may
            look a bit different.
          </p>
        )}

        <p
          style={{
            paddingLeft: "2em",
            paddingRight: "2em",
            display: "block",
          }}
        >
          Start Your Recording by clicking/tapping the Start Recording. Stop
          Recording with by clicking/tapping the same button again. The window
          will then close and you will be returned to your design.
        </p>
        <p
          style={{
            paddingLeft: "2em",
            paddingRight: "2em",
            display: "block",

            fontStyle: "italic",
            fontSize: ".75rem",
          }}
        >
          We care a lot about your privacy. We only access your microphone while
          you are recording a sound wave. As soon as you stop recording, we stop
          accessing your microphone. Any audio you record will only be stored on
          our servers if you purchase a print with the playback option. All
          audios are stored anonymously in a secure database. Read our Privacy
          Policy for more information.
        </p>
        <Button
          style={{ margin: "2em" }}
          variant="contained"
          color="primary"
          startIcon={recording ? <StopIcon /> : <RecIcon />}
          onClick={() => {
            recording ? stopRecording() : startRecording();
          }}
        >
          {recording ? "Stop Recording" : "Start Recording"}
        </Button>
        <Button
          style={{
            margin: "2em",
            display: "inline",
            backgroundColor: "#5f00ba",
            color: "white",
          }}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </div>
    );
  

  const handleOpen = () => {
    establishRecording();
    setOpen(true);
  };

 const handleClose = () => setOpen(false);

  async function establishRecording() {
    let newStream;
    try {
      newStream = await getAudioStream();
    }
    catch (error) {
      console.error(error);
    }
    setStream(newStream)
  }

  const getAudioStream = () => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    const params = { audio: true, video: false };
    return navigator.mediaDevices.getUserMedia(params);
  }
const startRecording = () => {
   
  const audioContext = new AudioContext();
  const recorder = new Recorder(audioContext);
  recorder.init(stream);
  console.log(stream);
  setRecording(true)
  setRecorder(recorder)

  recorder.start();
  console.log(recorder);
  }

  async function stopRecording() {
    console.log("calling stop recording");
    const { buffer } = await recorder.stop();
    if (buffer) {
      setRecording(false);
      console.log(buffer[0]);
      console.log(props);
      props.onRecordUpload(buffer[0]);
    }

  }

   
    return (
      <div>
        <Button
          variant="contained"
          style={{
            width: "100%",
            backgroundColor: "#5f00ba",
            color: "white",
          }}
          startIcon={<MicRoundedIcon />}
          onClick={() => handleOpen()}
        >
          Record Sound Wave
        </Button>
        <Modal
          open={open}
          onClose={() => handleClose}
          aria-labelledby="Record Your Soundwave"
          aria-describedby="Dialouge Box for Recording Your Owm Soundwave"
        >
          {body}
        </Modal>
      </div>
    );
  }


