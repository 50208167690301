import React from 'react'
export  const CanvasComponent = React.memo(  (props) => {
        const {reference,styling} =props;
        return (
           
            <canvas
            style={styling}
            ref={reference}
            >
            </canvas>
        )
    })
