import React, { useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./FixedSidebar.css";
import { setTemplateSelected } from "../features/SoundArtFeature/soundArtSlice"
import AppHeader from "./SidebarComponents/AppHeader";
import { useSelector, useDispatch } from "react-redux"
import SoundwaveComponent from "./SidebarComponents/SoundwaveComponent";
// Material UI
import TextOptionsComponent from "../features/TextFeature/TextOptionsComponent";
import PaletteIcon from "@material-ui/icons/Palette";
import SaveDesignModal from "./SidebarComponents/SaveDesignModal";
import ColorComponent from "../features/ColorFeature/ColorComponent";
import Paper from "@material-ui/core/Paper";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import CanvasOptionsComponent from "./SidebarComponents/TemplatingComponents/CanvasOptionsComponent";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import { Button } from "@material-ui/core";
import { changeOrientation } from "../features/SoundArtFeature/soundArtSlice"



export default function FixedSidebar(props) {


  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.soundArtReducer.isLoading)
  const soundwaveType = useSelector(state => state.soundArtReducer.soundwaveType)
  const subtitleDisabled = useSelector(state => state.soundArtReducer.subtitleDisabled)
  const [currentSelected, setCurrentSelected] = useState(0)
  const value = currentSelected;
  const orientation = useSelector(state => state.soundArtReducer.orientation)
  const containerStyle = {
    boxSizing: "border-box",
    alignItems: "center",
    lineHeight: "1",
    textAlign: "center",
    fontFamily: "inherit",
    width: "100%",
    padding: "10px 15px",
    backgroundColor: "#fff",

  };

  const selectedStyle = {
    backgroundColor: "#5f00ba",
    color: "white"
  }
  const notSelectedStyle = {

  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box boxShadow={0} p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };


  }

  if (!props.show) {

    return (
      <div
        className="sidebar"
      >
        <AppHeader showDescription={true} />
      </div>
    );
  }
  return (
    !props.showOptions ?
      <section aria-label="sidebar"
        className="sidebar"
      >
        {props.mobile ? null : (
          <AppHeader showDescription={false} />
        )}

        {props.show ? (
          <Paper
            square
            elevation={0}
            style={{
              flexGrow: 1,
              maxWidth: "100%",
              boxShadow: "none",


            }}
          >

            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              color="primary"
              disableElevation
              disabled={isLoading ? true : false}
              onClick={() => { dispatch(setTemplateSelected(false)) }}
              size="large"
              style={{
                width: "100%",
              }}
            >
              Choose A Different Template
            </Button>
            <AppBar
              style={{
                backgroundColor: "rgba(95, 0, 186, 0.1)",
                color: "rgba(95, 0, 186, 1)",
              }}
              position="static"
              elevation={0}
            >
              <Tabs
                value={currentSelected}
               // aria-label="tabs for configuring your special sound. There are 3 tabs: Soundwave, Text and Colors."
                indicatorColor="primary"
                variant="fullWidth"
                scrollButtons="on"
                centered
                onChange={(event, value) => setCurrentSelected(value)}
                elevation={0}
              >
                <Tab
                  icon={<VolumeUpIcon />}
                  label="Sound Wave"

                  {...a11yProps(0)}
                />
                <Tab
                  icon={<TextFormatIcon />}
                  label="Text"

                  {...a11yProps(1)}
                />
                <Tab
                  icon={<PaletteIcon />}
                  label="Style"

                  {...a11yProps(2)}
                />

              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div style={{ display: "block", width: "100%" }}>
                <div style={containerStyle}>
                  <h2 aria-label=" Select Layout">Select Layout</h2>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "space-between" }}>
                    <Button style={orientation === "Landscape" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeOrientation("Landscape"))}>Landscape</Button>
                    <Button style={orientation === "Square" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeOrientation("Square"))}>Square</Button>
                    <Button style={orientation === "Portrait" ? selectedStyle : notSelectedStyle} onClick={(e) => dispatch(changeOrientation("Portrait"))}>Portrait</Button>
                  </div>
                </div>
                <SoundwaveComponent
                  mobile={props.mobile}
                  mode={soundwaveType}
                  audioChanged={(audio) => props.onAudioUploaded(audio)}
                  userAudios={props.userAudios}
                  recordingUploaded= {(buffer) => { console.log("recording uploaded in Sidebar"); props.recordingUploaded(buffer)}}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ display: "block", width: "100%" }}>
                {" "}
                <TextOptionsComponent
                  title
                  type="title"
                />
                {!subtitleDisabled ? (
                  <TextOptionsComponent />
                ) : null}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ColorComponent />
            </TabPanel>

            <Button variant="contained"
              color="secondary"
              size="large"
              //   className="__continueBtn"
              style={{
                width: "100%",
                boxShadow: "none",
              }}
              onClick={(e) => { props.handleContinue(e) }}>Continue</Button>
          </Paper>
        ) : null}
      </section> :

      // OPTIONS FOR SIZE & MEDIUM
      //____________________________________________________________________________________
      <section aria-label="sidebar - layout options"
        className="sidebar">

        {props.mobile ? null : (
          <AppHeader showDescription={false} />
        )}
        <Paper
          square
          elevation={0}
          style={{
            flexGrow: 1,
            maxWidth: "100%",
            boxShadow: "none",
          }}
        >

          <Button
            startIcon={<ArrowBackIcon />}
            variant="contained"
            onClick={(e) => props.backToPersonalize(e)}
            size="large"
            style={{
              width: "100%",
              backgroundColor: "#5f00ba",
              color: "white",
              boxShadow: "none",
            }}
          >
            Personalize
          </Button>
          <CanvasOptionsComponent
            mobile={props.mobile} />
          <SaveDesignModal mobile={props.mobile} />
        </Paper></section>
    //___________________________________________________________________________________
  );

}
