import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import Client from "shopify-buy";
import {Provider} from "react-redux"
import store from "./store.js"
const client = Client.buildClient({
  storefrontAccessToken: "55da32ccd0db8c417c96e3c7bacbe9fc",
  domain: "yourspecialsound.com",
});




/*


export const client = new Client(typeBundle, {
  url: "https://your-special-sound.myshopify.com/api/graphql",
  fetcherOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token": "55da32ccd0db8c417c96e3c7bacbe9fc",
    },
  },
});*/

ReactDOM.render(
<Provider store={store}> 
<App client={client} />
</Provider>
, document.getElementById("root"));
