import "../../../features/ColorFeature/INITIALCOLORS"
import { GRADIENTS, PALETTES } from "../../../features/ColorFeature/INITIALCOLORS"
import iloveyou from "../../../audio/iloveyou.mp3";
import defaultAudio from "../../../audio/orcloop.mp3";
import bliss from "../../../audio/mb.mp3"
import heartbeat from "../../../audio/heartbeat-02a.mp3"

export const ILOVEYOU = {
  audio: iloveyou,
  template: "iloveyou",
  drawMode: "line", //see .drawModes property
  configSettings: null,
  title: "I love you.",
  landscape: true,
  subtitle: "",
  titleFont: "Caveat",
  subtitleFont: "Dancing Script",
  colorMode: "mono",
  titleStyle: false,
  titleWeight: false,
  titleSpacing: "3px",
  titleTextDecoration: "none",
  titleSize: 40,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleSize: 30,
  subtitleSpacing: "0px",
  titleColor: "#fff",
  subtitleColor: "#fff",
  colors: ["#fff", "#fff", "#fff", "#fff", "#fff"],
  useGradient: false,
  monoColor: "#fff",
  canvasColor: "#e94a4e",
  sampleSize: 644,
  lineWidth: 1.15,
  lineHeight: 0,
  scaledHeight: -40, // negative numbers scale the audio form size, positive numbers shrink it.
  spacing: 1,
  paddingWidth: 0,
  currentCanvasSelected: 10,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  shoRecording: false,
  subtitleDisabled: false,
  isMonoColor: true,
  currentAudio: null,
  titleAlign: "center",
  subtitleAlign: "center",
  currentDPI: 0,
  soundwaveType: "normal",
  isSquare: false,
  params: {
    template: "iloveyou",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    canvasColor: "",
    sampleSize: "",
    lineWidth: "",
    lineHeight: "",
    barWidth: "",
    barHeight: "",
    scaledHeight: "",
    paddingWidth: "",
    spacing: "",
    currentBuffer: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "",
    currentIsMonoBuffer: "",
    subtitleDisabled: "",
    isMonoColor: "",
    titleAlign: "",
    subtitleAlign: "",
    soundwaveType: "",
    uid: "",
  },

}

export const IMAGEINWAVE = {
  template: "weddingwavehtemp",
  audio: bliss,
  drawMode: "picture-line", //see .drawModes property
  configSettings: null,
  title: "Emma & James",
  subtitle: "10.08.2019",
  landscape: true,
  titleFont: "Dancing Script",
  subtitleFont: "Dancing Script",
  titleStyle: false,
  titleWeight: false,
  titleSpacing: "3px",
  titleTextDecoration: "none",
  titleSize: 35,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleSize: 18,
  subtitleSpacing: "0px",
  titleColor: "#484753",
  subtitleColor: "#484753",
  colors: ["#69d2e7", "#a7dbd8", "#e0e4cc", "#f38630", "#fa6900"],
  colorMode: "mono",
  useGradient: false,
  canvasColor: "#fff",
  sampleSize: 2048,
  lineWidth: 2.4,
  lineHeight: -12,
  barWidth: 0,
  barHeight: 0,
  scaledHeight: 0,
  spacing: 1,
  paddingWidth: 1,
  currentBuffer: null, // Current downsampled audio buffer
  imageVertical: 0,
  imageHorizontal: 0,
  currentCanvasSelected: 10,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  shoRecording: false,
  textinWave: false,
  isMonoColor: true,
  monoColor: "#fff",
  currentAudio: null,
  currentDPI: 0,
  waveImage: null,
  subtitleDisabled: false,
  titleAlign: "center",
  subtitleAlign: "center",
  soundwaveType: "picture",
  offsetX: 0.5,
  offsetY: 0.5,
  isSquare: false,
  params: {
    template: "weddingwavehtemp",
    drawMode: "",
    title: "",
    landscape: true,
    subtitle: "",
    titleFont: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    paddingWidth: "",
    canvasColor: "",
    sampleSize: "",
    lineWidth: "",
    lineHeight: "",
    currentDPI: "",
    scaledHeight: "", // negative numbers scale the audio form size, positive numbers shrink it.
    spacing: "",
    currentBuffer: "", // Current downsampled audio buffer
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "", //The current buffer but not downsampled
    currentIsMonoBuffer: "", //Whether or not the current buffer only has 1 audio channel (mon
    textinWave: "",
    waveImage: "",
    isMonoColor: "",
    titleAlign: "",
    subtitleAlign: "",
    subtitleDisabled: "",
    soundwaveType: "",
    offsetX: "",
    offsetY: "",
    uid: "",
  },
}

export const IMAGEBACKGROUND = {
  template: "weddingfullh",
  audio: defaultAudio,
  drawMode: "line", //see .drawModes property
  configSettings: null,
  title: "Happy Birthday to the world's best Dad! 💛 ",
  subtitle: "",
  landscape: true,
  titleFont: "Dancing Script",
  subtitleFont: "Helvetica",
  titleStyle: false,
  titleWeight: true,
  titleSpacing: "0.4px",
  titleTextDecoration: "none",
  titleSize: 25,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleDisabled: true,
  subtitleSize: 20,
  subtitleSpacing: "0px",
  titleColor: "#fff",
  textinWave: true,
  currentDPI: 0,
  subtitleColor: "#000",
  canvasColor: "#fff",
  colors: GRADIENTS[1],
  colorMode: "gradient",
  useGradient: true,
  monoColor: "#5f00ba",
  tempHeightArray: null,
  sampleSize: 394,
  lineWidth: 2.3,
  lineHeight: 0,
  scaledHeight: -30,
  paddingWidth: 0,
  spacing: 1,
  currentBuffer: null, // Current downsampled audio buffer
  offsetVertical: 0,
  offsetHorizontal: 0,
  currentCanvasSelected: 10,
  wavePosition: 0.25,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  shoRecording: false,
  isMonoColor: false,
  currentAudio: null,
  bgImage: null,
  titleAlign: "center",
  subtitleAlign: "center",
  useBG: true,
  soundwaveType: "normal",
  gotCapture: false,
  isSquare: false,
  offsetX: 0.5,
  offsetY: 0.5,
  params: {
    template: "weddingfullh",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    offsetX: "",
    offsetY: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    sampleSize: "",
    lineWidth: "",
    lineHeight: "",
    scaledHeight: "",
    paddingWidth: "",
    spacing: "",
    currentBuffer: "",
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "",
    currentIsMonoBuffer: "",
    subtitleDisabled: "",
    isMonoColor: "",
    bgImage: "",
    titleAlign: "",
    subtitleAlign: "",
    subtitleTextAnchor: "",
    soundwaveType: "",
    uid: "",
  },
}
export const HEARTTEMPLATE = {
  template: "hearttemplate",
  audio: defaultAudio,
  drawMode: "line",
  configSettings: null,
  title: "Katie & Allan",
  subtitle: "25.07.2021",
  landscape: true,
  titleFont: "Dancing Script",
  subtitleFont: "Dancing Script",
  titleStyle: false,
  titleWeight: true,
  titleSpacing: "0.4px",
  titleTextDecoration: "none",
  titleSize: 35,
  heartPosition:0.15,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleDisabled: false,
  subtitleSize: 20,
  subtitleSpacing: "0px",
  titleColor: "#fff",
  textinWave: true,
  currentDPI: 0,
  subtitleColor: "#000",
  canvasColor: "#fff",
  colors: GRADIENTS[13],
  colorMode: "gradient",
  useGradient: true,
  monoColor: "#5f00ba",
  tempHeightArray: null,
  sampleSize: 1024,
  lineWidth: 2.3,
  lineHeight: -10,
  scaledHeight: -30,
  paddingWidth: 0,
  spacing: 1,
  currentBuffer: null, // Current downsampled audio buffer
  offsetVertical: 0,
  offsetHorizontal: 0,
  currentCanvasSelected: 10,
  wavePosition: 0.37,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  shoRecording: false,
  isMonoColor: false,
  currentAudio: null,
  bgImage: null,
  titleAlign: "center",
  subtitleAlign: "center",
  useBG: true,
  soundwaveType: "normal",
  radius:18,
  gotCapture: false,
  isSquare: false,
  offsetX: 0.5,
  offsetY: 0.5,
  params: {
    template: "weddingfullh",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    offsetX: "",
    offsetY: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    sampleSize: "",
    lineWidth: "",
    lineHeight: "",
    scaledHeight: "",
    paddingWidth: "",
    spacing: "",
    currentBuffer: "",
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "",
    currentIsMonoBuffer: "",
    subtitleDisabled: "",
    isMonoColor: "",
    bgImage: "",
    titleAlign: "",
    subtitleAlign: "",
    subtitleTextAnchor: "",
    soundwaveType: "",
    uid: "",
  },
}

export const LINETEMPLATE = {
  audio: bliss,
  template: "cleanlinetemplate",
  drawMode: "line", //see .drawModes property
  configSettings: null,
  title: "Bliss - Muse",
  subtitle: "",
  landscape: true,
  titleFont: "Shadows Into Light",
  subtitleFont: "Dancing Script",
  titleStyle: false,
  titleWeight: false,
  titleSpacing: "3px",
  titleTextDecoration: "none",
  titleSize: 30,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleSize: 10,
  subtitleSpacing: "0px",
  titleColor: "#e10c7f",
  subtitleColor: "#e10c7f",
  colors: GRADIENTS[11],
  monoColor: "#5f00ba",
  colorMode: "gradient",
  useGradient: true,
  canvasColor: "#fff",
  sampleSize: 2048,
  lineWidth: 2.4,
  lineHeight: -12,
  barWidth: 0,
  paddingWidth: 0,
  barHeight: 0,
  scaledHeight: -20, // negative numbers scale the audio form size, positive numbers shrink it.
  radialLineWidth: 1.5,
  radialRotation: 0,
  radialMinThreshold: 20,
  spacing: 1,
  currentBuffer: null, // Current downsampled audio buffer
  imageVertical: -300,
  imageHorizontal: 0,
  currentDPI: 0,
  currentCanvasSelected: 10,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  shoRecording: false,
  textinWave: false,
  currentAudio: null,
  waveImage: null,
  isMonoColor: false,
  bgImage: null,
  useBG: false,
  titleAlign: "center",
  subtitleAlign: "center",
  soundwaveType: "normal",
  subtitleDisabled: false,
  isSquare: false,
  params: {
    template: "cleanlinetemplate",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    sampleSize: "",
    lineWidth: "",
    lineHeight: "",
    scaledHeight: "",
    paddingWidth: "",
    spacing: "",
    currentBuffer: "",
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "",
    currentIsMonoBuffer: "",
    subtitleDisabled: "",
    isMonoColor: "",
    bgImage: "",
    titleAlign: "",
    subtitleAlign: "",
    soundwaveType: "",
    uid: "",
  }
}

export const LINEARTEMPLATE = {
  template: "cleanbartemplate",
  audio: bliss,
  drawMode: "linear", //see .drawModes property
  configSettings: null,
  title: "Bar Chart Template",
  subtitle: "10.08.2019",
  landscape: true,
  titleFont: "Avenir Next",
  subtitleFont: "Avenir Next",
  titleStyle: false,
  titleWeight: false,
  paddingWidth: 0,
  titleSpacing: "0px",
  titleTextDecoration: "none",
  titleSize: 30,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleSize: 18,
  subtitleSpacing: "0px",
  titleColor: "#e10c7f",
  subtitleColor: "#24252b",
  colors: PALETTES[26],
  monoColor: "#5f00ba",
  colorMode: "palette",
  useGradient: false,
  canvasColor: "#fff",
  sampleSize: 60,
  lineWidth: 1.2,
  lineHeight: 1,
  barWidth: 12,
  barHeight: 16,
  radialLineWidth: 1.9,
  radialRotation: 0,
  radialMinThreshold: 20,
  spacing: 1,
  currentBuffer: null, // Current downsampled audio buffer
  imageVertical: -300,
  imageHorizontal: 0,
  currentDPI: 0,
  currentCanvasSelected: 10,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  waveImage: null,
  isMonoColor: false,
  shoRecording: false,
  textinWave: false,
  bgImage: null,
  currentAudio: null,
  useBG: false,
  titleAlign: "center",
  subtitleAlign: "center",
  soundwaveType: "normal",
  subtitleDisabled: false,
  isSquare: false,
  params: {
    template: "cleanbartemplate",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    canvasColor: "",
    sampleSize: "",
    scaledHeight: "", // negative numbers scale the audio form size, positive numbers shrink it.
    paddingWidth: "",
    spacing: "",
    currentBuffer: "", // Current downsampled audio buffer
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "", //The current buffer but not downsampled
    currentIsMonoBuffer: "", //Whether or not the current buffer only has 1 audio channel (mon
    waveImage: "",
    subtitleDisabled: "",
    isMonoColor: "",
    titleAlign: "",
    subtitleAlign: "",
    soundwaveType: "",
    uid: "",
  },
};

export const RADIAL = {
  template: "radialcleanwide",
  drawMode: "radial-line",
  audio: heartbeat,
  configSettings: null,
  title: "First Ultrasound",
  subtitle: "10.10.2019",
  landscape: false,
  titleFont: "Avenir Next",
  subtitleFont: "Helvetica",
  titleStyle: false,
  titleWeight: false,
  titleSpacing: "0px",
  paddingWidth: 0,
  titleTextDecoration: "none",
  titleSize: 39,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleSize: 22,
  subtitleSpacing: "0px",
  titleColor: "#e10c7f",
  subtitleColor: "#54434C",
  colors: GRADIENTS[1],
  colorMode: "gradient",
  useGradient: true,
  monoColor: "#5f00ba",
  canvasColor: "#fff",
  sampleSize: 1238,
  lineWidth: 5,
  lineHeight: 0,
  barWidth: 0,
  barHeight: 0,
  currentDPI: 0,
  radialLineWidth: 3,
  radialRotation: 0,
  radius: 78,
  spacing: 1,
  currentBuffer: null, // Current downsampled audio buffer
  imageVertical: -300,
  imageHorizontal: 0,
  currentCanvasSelected: 10,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  waveImage: null,
  isMonoColor: false,
  shoRecording: false,
  currentAudio: null,
  textinWave: false,
  titleAlign: "center",
  subtitleAlign: "center",
  soundwaveType: "radial",
  subtitleDisabled: false,
  isSquare: false,
  params: {
    template: "radialcleanwide",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    canvasColor: "",
    sampleSize: "",
    scaledHeight: "", // negative numbers scale the audio form size, positive numbers shrink it.
    paddingWidth: "",
    spacing: "",
    currentBuffer: "", // Current downsampled audio buffer
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "", //The current buffer but not downsampled
    currentIsMonoBuffer: "", //Whether or not the current buffer only has 1 audio channel (mon
    waveImage: "",
    subtitleDisabled: "",
    isMonoColor: "",
    titleAlign: "",
    subtitleAlign: "",
    soundwaveType: "",
    uid: "",
  },
}
export const RADIALCIRCLE = {
  template: "radialcircle",
  drawMode: "radial-circle",
  audio: heartbeat,
  configSettings: null,
  title: "First Ultrasound",
  subtitle: "10.10.2019",
  landscape: false,
  titleFont: "Avenir Next",
  subtitleFont: "Helvetica",
  titleStyle: false,
  titleWeight: false,
  titleSpacing: "0px",
  paddingWidth: 0,
  titleTextDecoration: "none",
  titleSize: 39,
  subtitleStyle: false,
  subtitleWeight: false,
  subtitleTextDecoration: "none",
  subtitleSize: 22,
  subtitleSpacing: "0px",
  titleColor: "#e10c7f",
  subtitleColor: "#54434C",
  colors: GRADIENTS[1],
  colorMode: "gradient",
  useGradient: true,
  monoColor: "#5f00ba",
  canvasColor: "#fff",
  sampleSize: 128,
  lineWidth: 5,
  lineHeight: 0,
  barWidth: 0,
  barHeight: 0,
  currentDPI: 0,
  radialLineWidth: 3,
  radialRotation: 0,
  radius: 237,
  spacing: 1,
  currentBuffer: null, // Current downsampled audio buffer
  imageVertical: -300,
  imageHorizontal: 0,
  currentCanvasSelected: 10,
  currentUnsampledBuffer: null, //The current buffer but not downsampled
  currentIsMonoBuffer: false, //Whether or not the current buffer only has 1 audio channel (mono)
  loading: true,
  waveImage: null,
  isMonoColor: false,
  shoRecording: false,
  currentAudio: null,
  textinWave: false,
  titleAlign: "center",
  subtitleAlign: "center",
  soundwaveType: "radial",
  subtitleDisabled: false,
  isSquare: false,
  params: {
    template: "radial-circle",
    drawMode: "",
    title: "",
    subtitle: "",
    titleFont: "",
    landscape: "",
    subtitleFont: "",
    titleStyle: "",
    titleWeight: "",
    titleSpacing: "",
    titleTextDecoration: "",
    titleSize: "",
    subtitleStyle: "",
    subtitleWeight: "",
    currentDPI: "",
    subtitleTextDecoration: "",
    subtitleSize: "",
    subtitleSpacing: "",
    titleColor: "",
    monoColor: "",
    subtitleColor: "",
    colors: "",
    colorMode: "",
    useGradient: "",
    canvasColor: "",
    sampleSize: "",
    scaledHeight: "", // negative numbers scale the audio form size, positive numbers shrink it.
    paddingWidth: "",
    spacing: "",
    currentBuffer: "", // Current downsampled audio buffer
    imageVertical: "",
    imageHorizontal: "",
    currentCanvasSelected: "",
    currentUnsampledBuffer: "", //The current buffer but not downsampled
    currentIsMonoBuffer: "", //Whether or not the current buffer only has 1 audio channel (mon
    waveImage: "",
    subtitleDisabled: "",
    isMonoColor: "",
    titleAlign: "",
    subtitleAlign: "",
    soundwaveType: "",
    uid: "",
  },
};

