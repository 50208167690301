import React from "react";
import Visualizer from "../../Visualizer";
import {useSelector} from "react-redux"
import {useEffect} from "react"
export default function TemplateConfigComponent  (props) {

  const currentAudio = useSelector(state => state.soundArtReducer.currentAudio)
  useEffect(() => {
    if(!props.templateSelected){
      const { history } = props;
      history.push("/");
    }
 
 
  }, [props, props.templateSelected])
    return (
      <div style={{margin:"auto"}}
     
      >
        <Visualizer
            drawFromTemplate={true}
            template={props.template}
            params={props.params}
            mobile={props.mobile}
            bgImage={props.bgImage}
            initialAudio={currentAudio}
            audio={props.audio}
            prepareCheckout={(design) =>props.prepareCheckout(design)}
            addUserAudio={(audio) => props.addUserAudio(audio)}
         
            {...props}
        />
       
   
      </div>
    );
  
}
/*
    <div width="4em" style={{ marginTop: "6em" }}>
          {" "}
          <UserAudiosComponent audios={this.props.audios} />
        </div>
*/
