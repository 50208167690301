import React, { Component } from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { Icon } from "@material-ui/core";
class LineItem extends Component {
  constructor(props) {
    super(props);
    this.totalPrice = this.totalPrice.bind(this);
  }
  totalPrice = () => {
    if (this.props.discount) {
      const totalPrice = (
        this.props.line_item.quantity * this.props.line_item.variant.price
      ).toFixed(2);

      const percentage = this.props.discount.value.percentage;

      const discountInPrice = (totalPrice * percentage) / 100;

      return discountInPrice;
    }
    return null;
  };

  render() {
    let dis = null;
    if (this.props.discount) {
      dis = this.totalPrice();
    }
    return (
      <li className="Line-item">
        <div className="Line-item__img"></div>
        <div className="Line-item__content">
          <div className="Line-item__content-row">
            <div className="Line-item__variant-title">
              {this.props.line_item.variant.title}
            </div>
            <span className="Line-item__title">
              {this.props.line_item.title}
            </span>
          </div>
          <div className="Line-item__content-row">
            <span className="Line-item__price">
              ${" "}
              {(
                this.props.line_item.quantity *
                this.props.line_item.variant.price
              ).toFixed(2)}
            </span>
            {this.props.discount ? (
              <div  style={{display:"flow-root"}} className="Line-item__content-row"> 
                <div style={{ color: "#736d6c", fontSize: "0.8em" }}>
                  <LocalOfferIcon></LocalOfferIcon>
                  {this.props.discount.title}{" "}
                  {dis ? (
                    <div className="Line-item__price">
                      <span style={{ color: "red" }}> $-{dis}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </li>
    );
  }
}

export default LineItem;
