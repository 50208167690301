import React from "react";
import "../App.css"
export default function FooterComponent  (props) {
 


    //DESKTOP:
    return (
      <footer className="footer">
        <p style={{ color: "#24252b" }}>
          Your Special Sound is owned and operated by Your Special Sound LLC, a
          Wyoming limited liability company.{" "}
        </p>
        <p style={{ display: "inline", padding: "0.5em", color: "#24252b" }}>
          © Your Special Sound LLC
        </p>{" "}
        <a
          href="https://yourspecialsound.com/privacy"
          style={{
            color: "#24252b",
            textDecoration: "underline",
          }}
        >
          Privacy Policy
        </a>{" "}
        <a
          href="https://yourspecialsound.com/terms"
          target="_blank" rel="noopener noreferrer"
          style={{
            color: "#24252b",
            textDecoration: "underline",
          }}
        >
          Terms of Service
        </a>{" "}
        <a
          href="https://yourspecialsound.com/refund"
          target="_blank" rel="noopener noreferrer"
          style={{
            color: "#24252b",
            textDecoration: "underline",
          }} 
        > 
          Returns & Refund Policy
        </a>{" "}
        <a style={{color:"#24252b"}} href="https://yourspecialsound.com/pages/accessibility"
        target="_blank" rel="noopener noreferrer">
          Accessibility Statement</a>
      </footer>
    );
  
}
