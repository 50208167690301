import React from "react";
import MobileRecordComponent from "./MobileRecordComponent";
import ModalRecordComponent from "./ModalRecordComponent";
import RadialConfigComponent from "./ConfigurationComponents/RadialConfigComponent";
import WaveConfigComponent from "./ConfigurationComponents/WaveConfigComponent";
import ImageConfigComponent from "./ConfigurationComponents/ImageConfigComponent";
import HeartConfigComponent from "./ConfigurationComponents/HeartConfigComponent";
import Button from "@material-ui/core/Button";
import UpArrowIcon from "@material-ui/icons/ArrowUpward";
import "../styles/sidebarStyles.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageIcon from "@material-ui/icons/Image";
import LinearConfigComponent from "./ConfigurationComponents/LinearConfigComponent";
import { useDispatch, useSelector } from "react-redux"
import { changeWaveImage, changeBgImage, setLoading, changeUnsampledBuffer } from "../../features/SoundArtFeature/soundArtSlice";
import { useMediaQuery } from 'react-responsive'
import UserAudioListComponent from "../SidebarComponents/UserAudiosListComponent"
import { ACCENT_COLOR } from "../../theme";
export default function SoundwaveComponent(props) {


  const dispatch = useDispatch();
  const waveImage = useSelector(state => state.soundArtReducer.waveImage)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  //const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  //const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })


  //const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  //nst isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const onImageUpload = (event) => {
    var file = event.target.files[0];
    console.log(file);
    console.log(waveImage);
    dispatch(setLoading(true))

    var reader = new FileReader();
    reader.onload = function (e) {
      if (e.target.result !== waveImage) {

        dispatch(changeWaveImage(e.target.result))
      }
      else {
        dispatch(setLoading(false))
      }

    };
    if (file) {
      reader.readAsDataURL(file);
    }


  }

  // const matches = useMediaQuery('(min-width:768px)');

  const onBgImageUpload = (event) => {
    console.log("uploading files");

    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      console.log("file name uploaded: " + file.name);

      dispatch(changeBgImage(e.target.result))

    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const onSoundUpload = event => {
    dispatch(setLoading(true))
    var file = event.target.files[0];
    console.log("sound upload: ");
    console.log(file);
    console.log(event.target.files);
    var reader = new FileReader();
    reader.onload = function (e) {
      props.audioChanged(e.target.result)

    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const onRecordUpload = (buffer) => {
    dispatch(setLoading(true))
    var file = buffer;
    var reader = new FileReader();
    reader.onload = function (e) {

    };
    if (file) {
      //console.log(file.buffer);
      //reader.readAsArrayBuffer(file.buffer);
    
     props.recordingUploaded(file.buffer);
    }
  }
  const containerStyle = {
    boxSizing: "border-box",
    alignItems: "center",
    lineHeight: "1",
    textAlign: "center",
    fontFamily: "inherit",
    width: "100%",
    padding: "10px 15px",
    backgroundColor: "#fff",

  };

  switch (props.mode) {
    case "picture":
      return (
        <div>

          <div style={containerStyle}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                lineHeight: "1.3",
                paddingBottom: "0.5em"
              }}
            >
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Your Audios</h2>
              {props.userAudios.length > 0 ? <UserAudioListComponent audios={props.userAudios} /> : <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} />}
              <h1 style={{ color: "black", fontSize: "1.5em" }}>
                Create or Upload Your Sound Wave
              </h1>
              {isMobile ? <MobileRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} mobile={props.mobile} />
                : <ModalRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} />}

              <input
                type="file"
                name="file"
                display="none"
                id="contained-button-file"
                accept=".mp3, .wav, .ogg"
                style={{ color: "white", padding: "2em", display: "none" }}
                onChange={e => onSoundUpload(e)}

              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UpArrowIcon />}
                  style={{
                    width: "100%",
                    marginTop: "1em",
                  }}
                >
                  Upload Audio
                </Button>
              </label>
              <p style={{textAlign:"left"}}>By uploading files through our design app you agree to our <a style={{color:"24252b"}} target="_blank" rel="noopener noreferrer" href="https://yourspecialsound.com/terms">Terms of Service.</a> See our <a  style={{color:"black"}}  href="https://yourspecialsound.com/privacy">Privacy Policy</a> for information about how we securely store your files.</p>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              {" "}
              <h2>Image Options</h2>
              <input
                type="file"
                name="file"
                id="contained-button-image"
                accept=".png,.jpeg,.jpg,.tiff"
                style={{ color: "white", padding: "2em", display: "none" }}
                onChange={e => onImageUpload(e)}
              />
              <label htmlFor="contained-button-image">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ImageIcon />}
                  component="span"
                  style={{
                    width: "100%",
                    marginRight: "2em",
                  }}
                >
                  Select Image
                </Button>
              </label>
              <ImageConfigComponent />


            </div>
          </div>
          <WaveConfigComponent waveImage />
        </div>
      );

    case "normal":
      return (
        <div>

          <div style={containerStyle}>
            <div style={{
              width: "100%",
              textAlign: "center",
              lineHeight: "1.3",
              paddingBottom: "0.5em"
            }}>
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Your Audios</h2>
              {props.userAudios.length > 0 ? <UserAudioListComponent audios={props.userAudios} /> : <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} />}
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Create or Upload Your Sound Wave</h2>
              <ModalRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} mobile={props.mobile} />
              <input
                type="file"
                name="file"
                id="contained-button-file"
                accept=".mp3, .wav, .ogg"
                style={{ color: "white", display: "none" }}
                onChange={e => onSoundUpload(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UpArrowIcon />}
                  style={{
                    width: "100%",
                    marginTop: "1em",
                  }}
                >
                  Upload
                </Button>
              </label>
              <p style={{textAlign:"left"}}>By uploading files through our design app you agree to our <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} href="https://yourspecialsound.com/terms">Terms of Service.</a> See our <a  style={{color:"black"}}  href="https://yourspecialsound.com/privacy">Privacy Policy</a> for information about how we securely store your files.</p>
            </div>
          </div>

          <WaveConfigComponent />
        </div>
      );

    case "bg-picture":
      return (
        <div>

          <div style={containerStyle}>
            <div style={{
              width: "100%",
              textAlign: "center",
              lineHeight: "1.3",
              paddingBottom: "0.5em"
            }}>
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Your Audios</h2>
              {props.userAudios.length > 0 ? <UserAudioListComponent audios={props.userAudios} /> : <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} />}
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Create or Upload Your Sound Wave</h2>
              <MobileRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} mobile={props.mobile} />
              <input
                type="file"
                name="file"
                id="contained-button-file"
                accept=".mp3, .wav, .ogg"
                style={{ color: "white", display: "none" }}
                onChange={e => onSoundUpload(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UpArrowIcon />}
                  style={{
                    width: "100%",
                    marginTop: "1em",
                  }}
                >
                  Upload
                </Button>
              </label>
              <p style={{textAlign:"left"}}>By uploading files through our design app you agree to our <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} href="https://yourspecialsound.com/terms">Terms of Service.</a> See our <a  style={{color:"black"}}  href="https://yourspecialsound.com/privacy">Privacy Policy</a> for information about how we securely store your files.</p>
              <div style={{ width: "100%", textAlign: "center" }}>
                {" "}
                <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Image Options</h2>
                <input
                  type="file"
                  name="file"
                  id="contained-button-image"
                  accept=".png,.jpeg,.jpg,.tiff"
                  style={{ color: "white", padding: "2em", display: "none" }}
                  onChange={e => onBgImageUpload(e)}
                />
                <label htmlFor="contained-button-image">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ImageIcon />}
                    component="span"
                    style={{
                      width: "100%",
                      marginRight: "2em",
                    }}
                  >
                    Select Image
                  </Button>
                </label>
                <ImageConfigComponent />
              </div>
            </div>
          </div>

          <WaveConfigComponent />
        </div>
      );
      case "heart":
      return (
        <div>

          <div style={containerStyle}>
            <div style={{
              width: "100%",
              textAlign: "center",
              lineHeight: "1.3",
              paddingBottom: "0.5em"
            }}>
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Your Audios</h2>
              {props.userAudios.length > 0 ? <UserAudioListComponent audios={props.userAudios} /> : <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} />}
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Create or Upload Your Sound Wave</h2>
              <MobileRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} mobile={props.mobile} />
              <input
                type="file"
                name="file"
                id="contained-button-file"
                accept=".mp3, .wav, .ogg"
                style={{ color: "white", display: "none" }}
                onChange={e => onSoundUpload(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UpArrowIcon />}
                  style={{
                    width: "100%",
                    marginTop: "1em",
                  }}
                >
                  Upload
                </Button>
              </label>
              <p style={{textAlign:"left"}}>By uploading files through our design app you agree to our <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} href="https://yourspecialsound.com/terms">Terms of Service.</a> See our <a  style={{color:"black"}}  href="https://yourspecialsound.com/privacy">Privacy Policy</a> for information about how we securely store your files.</p>
              <div style={{ width: "100%", textAlign: "center" }}>
                {" "}
                <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Image Options</h2>
                <input
                  type="file"
                  name="file"
                  id="contained-button-image"
                  accept=".png,.jpeg,.jpg,.tiff"
                  style={{ color: "white", padding: "2em", display: "none" }}
                  onChange={e => onBgImageUpload(e)}
                />
                <label htmlFor="contained-button-image">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ImageIcon />}
                    component="span"
                    style={{
                      width: "100%",
                      marginRight: "2em",
                    }}
                  >
                    Select Image
                  </Button>
                  <ImageConfigComponent />
                </label>
                <HeartConfigComponent />
              </div>
            </div>
          </div>

         
        </div>
      );


    case "radial":
      return (
        <div>

          <div style={containerStyle}>
            {" "}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                lineHeight: "1.3",
                paddingBottom: "0.5em"
              }}
            >
              {" "}
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Your Audios</h2>
              {props.userAudios.length > 0 ? <UserAudioListComponent audios={props.userAudios} /> : <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} />}
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Create or Upload Your Sound Wave</h2>
              <MobileRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} mobile={props.mobile} />
              <input
                type="file"
                name="file"
                id="contained-button-file"
                accept=".mp3, .wav, .ogg"
                style={{ color: "white", padding: "2em", display: "none" }}
                onChange={e => onSoundUpload(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UpArrowIcon />}
                  style={{
                    width: "100%",
                    marginTop: "1em",
                  }}
                >
                  Upload
                </Button>
              </label>
              <p style={{textAlign:"left"}}>By uploading files through our design app you agree to our <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} href="https://yourspecialsound.com/terms">Terms of Service.</a> See our <a  style={{color:"black"}}  href="https://yourspecialsound.com/privacy">Privacy Policy</a> for information about how we securely store your files.</p>
            </div>
          </div>
          <RadialConfigComponent />
        </div>
      );

    case "linear":
      return (
        <div>

          <div style={containerStyle}>
            {" "}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                lineHeight: "1.3",
                paddingBottom: "0.5em"
              }}
            >
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Your Audios</h2>
              {props.userAudios.length > 0 ? <UserAudioListComponent audios={props.userAudios} /> : <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} />}
              {" "}
              <h2 style={{ fontSize: props.mobile ? "1.3" : "1.5em" }}>Create or Upload Your Sound Wave</h2>
              <MobileRecordComponent onRecordUpload={(buffer) => onRecordUpload(buffer)} mobile={props.mobile} />
              <input
                type="file"
                name="file"
                id="contained-button-file"
                accept=".mp3, .wav, .ogg"
                style={{ color: "white", padding: "2em", display: "none" }}
                onChange={e => onSoundUpload(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UpArrowIcon />}
                  style={{
                    width: "100%",
                    marginTop: "1em",
                    backgroundColor: "#5f00ba",
                    color: "white",
                  }}
                >
                  Upload
                </Button>
              </label>
              <p style={{textAlign:"left"}}>By uploading files through our design app you agree to our <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} href="https://yourspecialsound.com/terms">Terms of Service.</a> See our <a  style={{color:"black"}}  href="https://yourspecialsound.com/privacy">Privacy Policy</a> for information about how we securely store your files.</p>
            </div>

          </div>
          <LinearConfigComponent />
        </div>
      );

    default: console.log("INVALID SOUNDWAVE TYPE SPECIFIED");
  }

}
