import React from "react";
import { Button } from "@material-ui/core";
import "./styling.css"
import "../../App.css"
export default function DesignProduct(props) {


  return (
    <div>
      <div
        className="designProduct"
      >
        <img style={props.style} alt="your special sound" src={props.image}></img>
        <div>
          <h3
            style={{
              marginTop: "2em",
              marginLeft: "1.8em",
              width: "100%",
              color: "black",
            }}
          >
            1x Your Special Sound {props.medium}
          </h3>
          <div
            style={{ marginLeft: "2em", color: "black" }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>Type:</span> {props.medium}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Size:</span> {props.size}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Orientation:</span> {props.orientation}
            </p>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.editDesign()}
            style={{
              marginLeft: "2em",
            }}
          >
            Edit Your Special Sound
          </Button>
          <Button
            onClick={() => props.removeDesign(props.lineItemId)}
            variant="text"
            size="small"
            color="primary"
            style={{
              display: "block",
              marginTop: "1em", size: "small",
              marginLeft: "2em",
            }}
          >
            Remove Design
          </Button>

        </div>

      </div>

    </div >
  );
}
