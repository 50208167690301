import React, { useState } from "react";
import { Button, Modal } from "@material-ui/core";
import MicRoundedIcon from "@material-ui/icons/MicRounded";
import Recorder from "recorder-js";
import RecIcon from "@material-ui/icons/FiberManualRecord";
import StopIcon from "@material-ui/icons/Stop";
import MicWarning from "../../assets/micWarning.jpg";
import symbol from "../../assets/logoforqr.png"
import "../SidebarComponents/MobileRecordComponent.css"
export default function MobileRecordComponent(props) {

  const [open, setOpen] = useState(false)
  const [stream, setStream] = useState(null)
  const [recording, setRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)


  const handleOpen = () => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    establishRecording();
    setOpen(true)
  };

  const handleClose = () => setOpen(false);

  async function establishRecording() {
    let newStream;
    try {
      newStream = await getAudioStream();
    }
    catch (error) {
      console.error(error);
    }
    setStream(newStream)
  }

  const getAudioStream = () => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    const params = { audio: true, video: false };
    return navigator.mediaDevices.getUserMedia(params);
  }
  const startRecording = () => {


    const audioContext = new AudioContext();
    const recorder = new Recorder(audioContext);
    recorder.init(stream);
    console.log(stream);
    setRecording(true)
    setRecorder(recorder)

    recorder.start();
    console.log(recorder);
  }

  async function stopRecording() {

    console.log("calling stop recording");
    const { buffer } = await recorder.stop();
    if (buffer) {
      setRecording(false);
      console.log(buffer[0]);
      console.log(props);
      props.onRecordUpload(buffer[0]);
    }


  }

  const body = (
    <div className="container">
      <div className="header"><h1 className="headerTitle">Record Soundwave</h1><Button onClick={() => handleClose()} variant="contained" color="primary" style={{ width: "100%" }}>Cancel Recording</Button></div>
      <div className="waver"> <p className="description">You need to give us access to your microphone to record.
        We store your recording securely and anonymously. Read our Privacy Policy for more information</p></div>

      <div className="lowBar"><button onClick={() => recording ? stopRecording() : startRecording()} className="mainBtn"><div className="recordSymbol"></div></button><p className="bigLabel">Record</p></div>
    </div>
  );

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{
          width: "100%",

        }}
        startIcon={<MicRoundedIcon />}
        onClick={() => handleOpen()}
      >
        Record Sound Wave
      </Button>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="Record Your Soundwave"
        aria-describedby="Dialouge Box for Recording Your Owm Soundwave"
      >
        {body}
      </Modal>
    </div>
  );

}
