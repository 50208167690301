import React, { useState } from "react";
import reactCSS from "reactcss";
import { ChromePicker } from "react-color";

function ColorFromSwatchComponent(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const styles = reactCSS({
    default: {
      color: {
        width: "30px",
        height: "30px",
        borderRadius: "25%",
        backgroundColor: props.currentColor,
      },
      swatch: {
        padding: "0px",
        background: "#fff",
        borderRadius: "25%",
        borderWidth: "thick",
        borderColor: "red",
        margin: "1rem",
        boxShadow: "0 0 0 .3px rgba(0,0,0)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div role="button" aria-label={props.label} style={styles.swatch} onClick={() => setDisplayColorPicker(!displayColorPicker)}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
          <ChromePicker
            color={props.currentColor}
            onChange={(color) => props.handleColorChange(color.hex, props.myIndex)}
          />
        </div>
      ) : null}
    </div>
  );

}

export default ColorFromSwatchComponent;
