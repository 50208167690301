import React from "react";
import logo from "../../assets/logo.svg";
import "../../App.css"
export default function AppHeader(props) {

  const desktopStyle = {
    boxSizing:"border-box",
    wdith: "100%",
    padding:"2em",
    backgroundColor: "#fff",
    fontFamily:"Cabin"

    
    
  };
  return (
    <div   className="appHeader" style={desktopStyle}>
      {" "}
      
      <div
        style={{
        
          height: "50px",
          margin:"0 auto",
          backgroundImage: `url(${logo})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition:props.CartStyle?"left":"center",
        }}
        
      > </div>
      {props.showDescription ? (
        <div>
        <p style={{ color: "black", fontSize: "1.2em", padding: "2em", lineHeight:"1.5", fontFamily:"Cabin",textAlign:"left" }}>
          Select a template to get started or watch our video below to learn how
          to use the design tools.
        </p>
     </div>
      ) : null}
    </div>
  );
}
//   <YoutubeComponent url="https://www.youtube.com/embed/WNShktLv7Sc"  />