import React from "react";
import UserAudioComponent from "./UserAudioComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./UserAudioComponent.css";
import { useSelector } from "react-redux"

export default function UserAudioListComponent(props) {
  const isLoading = useSelector(state => state.soundArtReducer.isLoading)

  const userAudios = isLoading ? <CircularProgress name="spinner" size="2rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} /> :
    props.audios.map(el => (
      <UserAudioComponent
        buffer={el.buffer}
        audio={el.audio}
        configSettings={el.configSettings}
        fontSettings={el.fontSettings}
        unsampledBuffer={el.unsampledBuffer}
        selected={el.selected}
        name={el.audioData.title}
        audioType="Uploaded Mp3"
        dateAdded="20.10.21"
        image={el.image}
        uuid={el.id}
        key={el.index}
        myAudioID={"#audio-" + el.index}
      />
    ));
  return <div className="ua_list">{userAudios}</div>;

}
