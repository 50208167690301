import React, {useState}  from "react";
import { useDispatch} from "react-redux"
import {
  Button,
  TextField,
  Modal,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  IconButton,
} from "@material-ui/core";

import {prepareCheckout, toggleExport} from "../../features/SoundArtFeature/soundArtSlice"
export default function SaveDesignModal (props) {

  const dispatch = useDispatch()
const [open, setOpen] = useState(false)
const [consent, setConsent] = useState(true)
const [newsletter, setNewsletter] = useState(false)
const [email, setEmail] = useState("")
  const handleOpen = () => {
  setOpen(true)
  };

  const handleClose = () => {
   dispatch(toggleExport())
  
  };
  const handleChange = () => {
   setConsent(!consent)
   setNewsletter(!newsletter);
  };
 const handleSave = () => {
   dispatch(toggleExport())
   if (consent){
    
   }
    let info = { consent: consent, email: email };
//    EventEmitter.dispatch("saveDesign", info);
    //Need to implement basic validation of email.
    handleClose();
  };


    const mobileStyle = {
      // backgroundColor: "#5f00ba",
      background:
        "linear-gradient(0deg,rgba(255, 242, 241, 1) 0%,rgba(255, 255, 255, 1) 100%)",
      width: "100%",
      color: "black",
      alignItems: "center",
      position: "relative",
      left: "0%",
      top: "12.5%",
    };

    const body = (
      <div
        style={
          props.mobile
            ? mobileStyle
            : {
                background: "white",
                width: "40%",
                alignItems: "center",
                position: "absolute",
                padding: "2em",
                border: "2px",
                borderColor: "white",
                left: "30%",
                top: "20%",
              }
        }
      >

        <h2
          style={{
            color: "black",
            textAlign: "center",
            paddingTop: "1em",
          }}
        >
          Save Your Design
        </h2>
        <div
          style={{
            padding: "2em",
            display: "block",
            backgroundColor: "#white",
          }}
        >
          {" "}
          <p
            style={{
              display: "block",
              color: "black",
              backgroundColor: "white",
            }}
          >
            Enter your email below and we will send you a link that allows you to come back
            and edit your design at any time.
          </p>

          <p   style={{
              display: "block",
              color: "black",
              backgroundColor: "white",
            }}>In the meantime, we will store any images or audio you upload securely. Read more about that here.</p>
          <TextField
            id="filled-full-width"
            label="Email"
            type="email"
            placeholder="Enter Your Email"
            fullWidth
            margin="normal"
            onChange={(event) => setEmail(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
          <FormGroup style={{ paddingTop: "1em", paddingBottom: "1em" }} row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  onChange={handleChange}
                  name="consent"
                />
              }
              label={
                <Typography style={{ color: "black" }}>
                  I agree to recieve promotional emails from Your Special Sound.
                </Typography>
              }
              color="#000"
            />
          </FormGroup>
          <Button
            variant="contained"
            size="large"
            onClick={handleSave}
            fullWidth
            style={{
              display: "block",
              backgroundColor: "#5f00ba",
              color: "white",
            }}
          >
            Save My Design
          </Button>
          <Button
            size="large"
            fullWidth
            onClick={handleClose}
            style={{ display: "block" }}
          >
            No Thanks
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleOpen}
          style={{
          
            width: "100%",
            padding: props.mobile ? "1em" : "",
          }}
        >
          Continue
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="Save Your Design"
          aria-describedby="Dialouge Box for Saving Your Design"
        >
          {body}
        </Modal>
      </div>
    );
  
}
