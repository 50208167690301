export const SIZE = {
  SMALL: "Small (16 x 12)",
  MEDIUM: "Medium (24 x 18)",
  LARGE: "Large (32 x 24)",

};
export const MEDIUM = {
  PRINT: "Print",
  CANVAS: "Canvas",
  FRAMED: "Framed Print",

};

export const ORIENTATION = {
  LANDSCAPE: "Landscape",
  PORTRAIT: "Portrait",
  SQUARE: "Square"
}

export const SOUNDWAVETYPE = {
  NORMAL: "normal",
  PICTURE: "picture",
  RADIAL: "radial",
  BGIMAGE: "bg-picture",
  LINEAR: "linear",
  HEART:"heart",

}

export const DRAWMODE = {
  LINEAR: "linear",
  LINE: "line",
  RADIAL_LINE: "radial-line",
  RADIAL_CIRCLE: "radial-circle",
  HEART: "heart",
  PICTURELINE: "picture-line",
};


// CANVAS IDS:
export const CANVASSMALLVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5OTE4OTQzMjM5Mw==";
export const CANVASMEDIUMVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5OTE4OTQ2NTE2MQ==";
export const CANVASLARGEVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5OTE4OTQ5NzkyOQ==";
//PRINTS IDS:
export const PRINTSMALLVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5OTIxNjQ2NTk5Mw==";
export const PRINTMEDIUMVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5OTIxNjQ5ODc2MQ==";
export const PRINTLARGEVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5OTIxNjUzMTUyOQ==";
// FRAMED:
export const PRINTSMALLFRAMEDVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTczNDk4NzgxNzAzMw==";
export const PRINTSMEDIUMFRAMEDVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTczNDk4Nzg0OTgwMQ==";
export const PRINTSLARGEFRAMEDVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTczNDk4Nzg4MjU2OQ==";
// UPPSEL & CROSS SELL IDS:
export const DIGITALDOWNLOADVARIANTID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ1MzA3MTI0NDUwMDE=";


