import React from "react";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignCentertIcon from "@material-ui/icons/FormatAlignCenter";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import { Slider } from "@material-ui/core";
import ColorFromSwatchComponent from "../ColorFeature/ColorFromSwatchComponent.jsx";
import { changeTitleColor,changeSubtitleColor } from "../ColorFeature/colorFeatureSlice.jsx";
import { useDispatch, useSelector } from "react-redux"
import {
  changeSubtitle, changeTitle, changeTitleSize, changeSubtitleSize, changeTitleFont, changeSubtitleFont, toggleTitleWeight,
  toggleSubtitleWeight, toggleTitleStyle, toggleSubtitleStyle, changeTitleAlign, changeSubtitleAlign
} from "./textFeatureSlice"
import {
  IconButton,
  FormControl,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
export default function TextOptionsComponent(props) {
  const dispatch = useDispatch();
  const text = useSelector(state => props.title ? state.textReducer.title : state.textReducer.subtitle)
  const fontSize = useSelector(state => props.title ? state.textReducer.titleSize : state.textReducer.subtitleSize)
  const font = useSelector(state => props.title ? state.textReducer.titleFont : state.textReducer.subtitleFont)
  const bold = useSelector(state => props.title ? state.textReducer.titleWeight : state.textReducer.subtitleWeight)
  const italic = useSelector(state => props.title ? state.textReducer.titleStyle : state.textReducer.subtitleStyle)
  const titleColor = useSelector(state => state.colorReducer.titleColor)
  const subtitleColor = useSelector(state => state.colorReducer.subtitleColor)
  const item = { margin: "0 5px" };


  const sliderStyle = {
    marginLeft: "2em",
    paddingRight: "2em",
  };



  const fonts = () => {
    const possibleFonts = ["Helvetica", "Arial", "Caveat", "Dancing Script", "Clarkson Script", "Satisfy", "Avenir Next", "Shadows Into Light", "Festive"]
    return possibleFonts.map((el, index) => (

      <MenuItem value={el} key={index}>
        <em aria-label={el} style={{ fontFamily: `${el}` }}>{el}</em>
      </MenuItem>
    ))
  }


  return (
    <div>
      <FormControl
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection:"row",
          textAlign: "left",
          width: "100%"
        }}
      > <TextField

          label={props.type === "title" ? "Title" : "Subtitle"}
          variant="outlined"
          fullWidth
          margin="normal"
          value={text}
          onChange={(event) => dispatch(props.title ? changeTitle(event.target.value) : changeSubtitle(event.target.value))}
        />
      </FormControl>
      { props.type === "title"?
   <div style={item}>
   <ColorFromSwatchComponent
     label="Title Color"
     handleColorChange={(color) => dispatch(changeTitleColor(color))}
     currentColor={titleColor}
   />
 </div>:
 <div style={item}>
   <ColorFromSwatchComponent
     label="Subtitle Color"
     handleColorChange={(color) => dispatch(changeSubtitleColor(color))}
     currentColor={subtitleColor}
   />
 </div>
        }
      <Select value={font} defaultValue={font} onChange={(event) => dispatch(props.title ? changeTitleFont(event.target.value) : changeSubtitleFont(event.target.value))}>
        {fonts()}
      </Select>
      <IconButton
        onClick={() => dispatch(props.title ? changeTitleAlign("left") : changeSubtitleAlign("left"))}
        aria-label="left text Align"
        style={{ color: "#5f00ba" }}
      >
        <FormatAlignLeftIcon />
      </IconButton>
      <IconButton
        onClick={() => dispatch(props.title ? changeTitleAlign("center") : changeSubtitleAlign("center"))}
        aria-label="center text Align"
        style={{ color: "#5f00ba" }}
      >
        <FormatAlignCentertIcon />
      </IconButton>
      <IconButton
        onClick={() => dispatch(props.title ? changeTitleAlign("right") : changeSubtitleAlign("right"))}
        aria-label="right text Align"
        style={{ color: "#5f00ba" }}
      >
        <FormatAlignRightIcon />
      </IconButton>
      <IconButton
        onClick={() => dispatch(props.title ? toggleTitleWeight() : toggleSubtitleWeight())}
        aria-label={`bold: ${bold}`}
        style={{ color: "#5f00ba" }}
      >
        <FormatBoldIcon />
      </IconButton>
      <IconButton
        onClick={() => dispatch(props.title ? toggleTitleStyle() : toggleSubtitleStyle())}
        aria-label={`italic: ${italic}`}
        style={{ color: "#5f00ba" }}
      >
        <FormatItalicIcon />
      </IconButton>

      {props.type === "title" ? (
        <p aria-hidden="true" id="titleSlider" style={{ fontSize: "12pt" }}>Title Size:</p>
      ) : (
        <p aria-hidden="true" id="subtitleSlider" style={{ fontSize: "12pt" }}>Subtitle Size:</p>
      )}

      <Slider
        value={fontSize}
        aria-labelledby={props.title ? "titleSlider" : "subtitleSlider"}
        valueLabelDisplay="auto"
        defaultValue={fontSize}
        step={1}
        min={5}
        max={80}
        onChange={(event, newValue) => dispatch(props.title ? changeTitleSize(newValue) : changeSubtitleSize(newValue))}
        style={{ sliderStyle }}
      />
    </div>
  );

}
