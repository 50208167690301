import { createSlice } from "@reduxjs/toolkit";
import { ILOVEYOU, LINEARTEMPLATE, RADIAL, IMAGEBACKGROUND, IMAGEINWAVE, LINETEMPLATE, HEARTTEMPLATE } from "../../Components/SidebarComponents/TemplatingComponents/Templates"
import { PALETTES, GRADIENTS } from "./INITIALCOLORS"


export const randomHEXColor = () => { return "#" + (Math.floor(Math.random() * 16777215).toString(16)) }
const colorFeatureSlice = createSlice({
    name: "colors",
    initialState: {
        template: "",
        palettes: PALETTES,
        gradients: GRADIENTS,
        currentPalette: PALETTES[26],
        currentGradient: GRADIENTS[11],
        colorMode: "gradient", //palette,gradient or mono
        titleColor: "#24252b",
        subtitleColor: "#24252b",
        canvasColor: "#fff",
        monoColor: "#e0e4cc",
        createPaletteEnabled: false,
    },
    reducers: {
        initColors(state, { payload }) {
            state.template = payload
            switch (payload) {
                case "iloveyou":
                    state.titleColor = ILOVEYOU.titleColor
                    state.subtitleColor = ILOVEYOU.subtitleColor
                    state.canvasColor = ILOVEYOU.canvasColor
                    state.colorMode = ILOVEYOU.colorMode
                    state.monoColor = ILOVEYOU.monoColor
                    state.currentPalette = PALETTES[0]
                    state.currentGradient = GRADIENTS[0]




                    break;
                case "cleanlinetemplate":
                    state.titleColor = LINETEMPLATE.titleColor
                    state.subtitleColor = LINETEMPLATE.subtitleColor
                    state.canvasColor = LINETEMPLATE.canvasColor
                    state.colorMode = LINETEMPLATE.colorMode
                    state.monoColor = LINETEMPLATE.monoColor
                    state.currentPalette = PALETTES[0]
                    state.currentGradient = LINETEMPLATE.colors
                    break;
                case "cleanbartemplate":
                    state.titleColor = LINEARTEMPLATE.titleColor
                    state.subtitleColor = LINEARTEMPLATE.subtitleColor
                    state.canvasColor = LINEARTEMPLATE.canvasColor
                    state.colorMode = LINEARTEMPLATE.colorMode
                    state.monoColor = LINEARTEMPLATE.monoColor
                    state.currentPalette = LINEARTEMPLATE.colors
                    state.currentGradient = GRADIENTS[0]


                    break;
                case "radialcleanwide":
                    state.titleColor = RADIAL.titleColor
                    state.subtitleColor = RADIAL.subtitleColor
                    state.canvasColor = RADIAL.canvasColor
                    state.colorMode = RADIAL.colorMode
                    state.monoColor = RADIAL.monoColor
                    state.currentPalette = RADIAL.colors
                    state.currentGradient = GRADIENTS[1]

                    break;

                case "weddingfullh":
                    state.titleColor = IMAGEBACKGROUND.titleColor
                    state.subtitleColor = IMAGEBACKGROUND.subtitleColor
                    state.canvasColor = IMAGEBACKGROUND.canvasColor
                    state.colorMode = IMAGEBACKGROUND.colorMode
                    state.monoColor = IMAGEBACKGROUND.monoColor
                    state.currentPalette = PALETTES[0]
                    state.currentGradient = IMAGEBACKGROUND.colors
                    break;
                case "hearttemplate":
                    state.titleColor = HEARTTEMPLATE.titleColor
                    state.subtitleColor = HEARTTEMPLATE.subtitleColor
                    state.canvasColor = HEARTTEMPLATE.canvasColor
                    state.colorMode = HEARTTEMPLATE.colorMode
                    state.monoColor = HEARTTEMPLATE.monoColor
                    state.currentPalette = PALETTES[0]
                    state.currentGradient = HEARTTEMPLATE.colors
                    break;
                case "weddingwavehtemp":
                    state.titleColor = IMAGEINWAVE.titleColor
                    state.subtitleColor = IMAGEINWAVE.subtitleColor
                    state.canvasColor = IMAGEINWAVE.canvasColor
                    state.colorMode = IMAGEINWAVE.colorMode
                    state.monoColor = IMAGEINWAVE.canvasColor
                    state.currentPalette = IMAGEINWAVE.colors
                    state.currentGradient = IMAGEINWAVE.colors


                    break;

                default:

                    break;

            }

        },

        changePalette(state, action) {
            state.currentPalette = action.payload
        },
        changeGradient(state, action) {
            state.currentGradient = action.payload;
        },
        changeColorMode(state, action) {
            state.colorMode = action.payload
        },
        changeTitleColor(state, action) {
            state.titleColor = action.payload
        },
        changeSubtitleColor(state, action) {

            state.subtitleColor = action.payload
        },
        changeCanvasColor(state, action) {
            state.canvasColor = action.payload
            if (state.template === "weddingwavehtemp") state.monoColor = state.canvasColor
        },
        changeMonoWaveColor(state, action) {
            state.monoColor = action.payload
        },
        addPalette(state, action) {
            state.palettes.push(action.payload)
            state.currentPalette = state.palettes[state.palettes.length - 1]
            state.createPaletteEnabled = false;
        },
        addGradient(state, action) {
            state.gradients.push(action.payload);
            state.currentGradient = state.gradients[state.gradients.length - 1]
            state.createPaletteEnabled = false;
        },
        toggleCreatePalette(state) {
            state.createPaletteEnabled = !state.createPaletteEnabled
        }
        
    }

})

export const { initColors, changeCanvasColor, changeColorMode, changeGradient, changePalette, changeMonoWaveColor, changeSubtitleColor, changeTitleColor, addGradient, addPalette, toggleCreatePalette } = colorFeatureSlice.actions
export default colorFeatureSlice.reducer