import React from 'react'
import { Slider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux"
import { changeOffsetX, changeOffsetY } from "../../../features/SoundArtFeature/soundArtSlice"



function ImageConfigComponent() {

    const dispatch = useDispatch()
    const offsetX = useSelector(state => state.soundArtReducer.offsetX)
    const offsetY = useSelector(state => state.soundArtReducer.offsetY)

    const sliderStyle = {
        marginLeft: "2em",
        paddingRight: "2em",
    };

    return (
        <div style={{ paddingLeft: "2em", paddingRight: "2em" }}>
            <p style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>Horizontal Image Position:</p>

            <Slider
                value={offsetX}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                defaultValue={offsetX}
                step={0.01}
                min={0}
                max={1}
                onChange={(event, newValue) => { event.preventDefault(); dispatch(changeOffsetX(newValue)) }}
                style={{ sliderStyle }}
            />
            <p style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}>
                Vertical Image Position:
            </p>

            <Slider
                defaultValue={offsetY}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={0.01}
                min={0}
                max={1}
                onChange={(event, newValue) => { event.preventDefault(); dispatch(changeOffsetY(newValue)) }}
                style={{ sliderStyle }}
            />


        </div>
    )
}

ImageConfigComponent.propTypes = {

}

export default ImageConfigComponent

