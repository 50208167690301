import React, { useState } from "react";
import { Button, Modal, IconButton } from "@material-ui/core";
import "./imageResolutionModal.css"
import CloseIcon from "@material-ui/icons/Close";

export default function ImageResolutionModal (props) {
const [open, setOpen] = useState(false)
/*
    EventEmitter.subscribe(
      "openResModal",
      () => {
        this.handleOpen();
      },
      "ImageResolutionModal"
    );*/
  
  const handleOpen = () => setOpen(true);


  const handleClose = () => setOpen(false);


  
    const body = (
      <div
        style={{
          backgroundColor: "white",
          width: "40%",
          alignItems: "center",
          position: "absolute",
          padding: "2em",
          border: "2px",
          borderColor: "white",
          left: "30%",
          top: "20%",
        }}
      >
        <IconButton
          onClick={() => handleClose()}
          style={{ color: "#5f00ba", float: "right" }}
        >
          <CloseIcon />{" "}
        </IconButton>
        <h2
          style={{
            color: "black",
            textAlign: "center",
          }}
        >
          Image Resolution Too Low
        </h2>
        <div
          style={{
            padding: "2em",
            display: "block",
            backgroundColor: "#white",
          }}
        >
          {" "}
          <div
            style={{
              display: "block",
              color: "black",
              backgroundColor: "white",
            }}
          >
            The Image you selected is too small to print. These are the minimum
            requirements for a good result:
            <p style={{ fontWeight: "bold" }}>Canvas:</p>
            Small (10x8 or 24cmx20cm): 2000x2000 Medium (10x8 or 24cmx20cm):
            4000x4000 Large (10x8 or 24cmx20cm): 4000x4000
            <p style={{ fontWeight: "bold" }}>Prints:</p>
            <p>
              Small (10x8 or 24cmx20cm): 2000x2000 Medium (10x8 or 24cmx20cm):
              4000x4000 Large (10x8 or 24cmx20cm): 4000x4000
            </p>
          </div>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleClose()}
            fullWidth
            style={{
              display: "block",
              backgroundColor: "#5f00ba",
              color: "white",
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <Modal
          open={open}
          onClose={() => handleClose()}
          aria-labelledby="Image Resolution Too Low Warning"
          aria-describedby="The Image You Selected Is Too Low."
        >
          {body}
        </Modal>
      </div>
    );
  
}
