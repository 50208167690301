import React, { useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import ColorFromSwatchComponent from "./ColorFromSwatchComponent.jsx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {useDispatch} from "react-redux";
import "./ColorPaletteComponent.css";
import { changeGradient, changePalette, randomHEXColor } from "./colorFeatureSlice";
import {addPalette,addGradient,toggleCreatePalette} from "./colorFeatureSlice";
export default function PaletteCreatorComponent (props)  {
  const {clrMode} = props;
  const maxColors = clrMode === "gradient"?2:5;
  const presetColors = clrMode === "gradient"? [[randomHEXColor()],[randomHEXColor()]]: [[randomHEXColor()],[randomHEXColor()]];
  const [colors, setColors] = useState(presetColors)
  const dispatch = useDispatch();


 const handlePaletteChange =(color, index) => {
  
    const newColors = [
      ...colors.slice(0, index),
      color,
      ...colors.slice(index + 1),
    ];
    setColors(newColors);
    if (clrMode === "gradient")dispatch(changeGradient(newColors))
    else if (clrMode === "palette") dispatch(changePalette(newColors))
  }

 
    const addColor = () => {
      const color = [randomHEXColor()];
      if (colors.length < 5) {
        setColors([...colors, color]);
      }
    };

    const paragraphStyle ={
     fontFamily:"Cabin",
      fontSize:"14px",
      lineHeight:"1.5"
    }

    const label = clrMode === "gradient"? "Save Gradient" : "Save Palette";
 
    return (
      <div className="fade-in"  style={{ textAlign: "center" }}>

        {!clrMode === "gradient"?
        <p style={paragraphStyle}>
          Tap or click on the color swatch to change its color. Tap or click on
          the swatch with a plus sign to add more. When done tap or click "Save
          Palette"
        </p>
        :
        <p style={paragraphStyle}>
          Tap or click on the color swatch to change its color. When done tap or click "Save
          Palette"
        </p>
      }
        <div style={{ display: "flex", alignContent:"center", alignItems:"center", flexDirection:"column" }}>
          {
            <div style={{display:"inline-flex"}}> {colors.map((el, index) => (
              <ColorFromSwatchComponent
                currentColor={el}
                myIndex={index}
                handleColorChange={(color,index) =>handlePaletteChange(color,index)}
                key={index}
              />
            ))}</div>
          }

          {colors.length >= maxColors? null : (
            <IconButton
              onClick={() => addColor()}
              color="primary"
              aria-label="add color to palette."
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}
        </div>
        <Button onClick={() => dispatch(clrMode === "gradient"?addGradient(colors):addPalette(colors))}>{label}</Button>
        <Button onClick={() => dispatch(toggleCreatePalette())}>Cancel</Button>
      </div>
    );
  
}
