
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux"
import ImageResolutionModal from "./ImageResolutionModal";
import { CanvasComponent } from "./CanvasComponent";
import "./SidebarComponents/TemplatingComponents/Templates"
import { DRAWMODE, MEDIUM, ORIENTATION } from "../constants"
import { randomHEXColor } from '../features/ColorFeature/colorFeatureSlice';
import { prepareCheckout, setLoading, toggleExport, setParams, changeOffsetX, changeOffsetY } from "../features/SoundArtFeature/soundArtSlice";
import "../App.css"
import frameImage from "../assets/frame_transparent.png"
import { PRIMARY_COLOR } from '../theme';

//import babypicGS from "../assets/babypicGS.jpg";
export default function Visualizer(props) {
  const dc = useRef()
  const backgroundCanvas = useRef()
  const waveCanvas = useRef()
  const dispatch = useDispatch();

  // DEFINITION AND SUBSCRIPTION TO TEXT BASED FEATURES:

  const title = useSelector(state => state.textReducer.title)
  const subtitle = useSelector(state => state.textReducer.subtitle)
  const titleSize = useSelector(state => state.textReducer.titleSize)
  const subtitleSize = useSelector(state => state.textReducer.subtitleSize)
  const titleFont = useSelector(state => state.textReducer.titleFont)
  const subtitleFont = useSelector(state => state.textReducer.subtitleFont)
  const titleWeight = useSelector(state => state.textReducer.titleWeight)
  const subtitleWeight = useSelector(state => state.textReducer.subtitleWeight)
  const titleStyle = useSelector(state => state.textReducer.titleStyle)
  const subtitleStyle = useSelector(state => state.textReducer.subtitleStyle)
  const titleAlign = useSelector(state => state.textReducer.titleAlign)
  const subtitleAlign = useSelector(state => state.textReducer.subtitleAlign)
  const titleVerticalAlign = useSelector(state => state.textReducer.titleVerticalAlign)
  const subtitleVerticalAlign = useSelector(state => state.textReducer.subtitleVerticalAlign)
  //__________________________________

  // DEFINITION AND SUBSCRIPTION TO COLOR BASED FEATURES:

  const currentPalette = useSelector(state => state.colorReducer.currentPalette)
  const currentGradient = useSelector(state => state.colorReducer.currentGradient)
  const colorMode = useSelector(state => state.colorReducer.colorMode)
  const titleColor = useSelector(state => state.colorReducer.titleColor)
  const subtitleColor = useSelector(state => state.colorReducer.subtitleColor)
  const canvasColor = useSelector(state => state.colorReducer.canvasColor)
  const currentMonoColor = useSelector(state => state.colorReducer.monoColor)
  //__________________________________

  // DEFINITION AND SUBSCRIPTION TO SOUND ART BASED FEATURES:

  const currentAudio = useSelector(state => state.soundArtReducer.currentAudio)
  const landscape = useSelector(state => state.soundArtReducer.landscape)
  const drawMode = useSelector(state => state.soundArtReducer.drawMode)
  const exportingCanvas = useSelector(state => state.soundArtReducer.exportingCanvas)
  const isLoading = useSelector(state => state.soundArtReducer.isLoading)
  const wavePosition = useSelector(state => state.soundArtReducer.wavePosition)
  const lineWidth = useSelector(state => state.soundArtReducer.lineWidth)
  const lineHeight = useSelector(state => state.soundArtReducer.lineHeight)
  const barWidth = useSelector(state => state.soundArtReducer.barWidth)
  const barHeight = useSelector(state => state.soundArtReducer.barHeight)
  const radius = useSelector(state => state.soundArtReducer.radius)
  const spacing = useSelector(state => state.soundArtReducer.spacing)
  const sampleSize = useSelector(state => state.soundArtReducer.sampleSize)
  const waveImage = useSelector(state => state.soundArtReducer.waveImage)
  const bgImage = useSelector(state => state.soundArtReducer.bgImage)
  const useBG = useSelector(state => state.soundArtReducer.useBG)
  const offsetX = useSelector(state => state.soundArtReducer.offsetX)
  const offsetY = useSelector(state => state.soundArtReducer.offsetY)
  const imageHorizontal = useSelector(state => state.soundArtReducer.imageHorizontal)
  const imageVertical = useSelector(state => state.soundArtReducer.imageVertical)
  const heartPosition = useSelector(state => state.soundArtReducer.heartPosition)
  const textinWave = useSelector(state => state.soundArtReducer.textInWave)
  const isSquare = useSelector(state => state.soundArtReducer.isSquare)
  const params = useSelector(state => state.soundArtReducer.params)
  const heartTemplate = useSelector(state => state.soundArtReducer.heartTemplate)


  const size = useSelector(state => state.soundArtReducer.size)
  const medium = useSelector(state => state.soundArtReducer.medium)
  const orientation = useSelector(state => state.soundArtReducer.orientation)


  //__________________________________

  // DEFINITION AND SUBSCRIPTION TO SOUNDWAVE BASED FEATURES:
  const currentIsMonoBuffer = useSelector(state => state.soundArtReducer.currentIsMonoBuffer)
  const [currentBuffer, setCurrentBuffer] = useState([])
  const [currentUnsampledBuffer, setCurrentUnsampledBuffer] = useState(props.initialAudio)


  useEffect(() => {
    console.log("running the soundwave useEffect!");

    drawSoundwave(currentUnsampledBuffer)
  }, [props.isRecording])

  useEffect(() => {
    console.log("running the soundwave sound upload useEffect!");
    if (props.audio) drawSoundwave(props.audio)
  }, [props.audio])

  useEffect(() => {

    if (currentBuffer.length > 0 ) {
      visualize(currentUnsampledBuffer)
    }

  }, [sampleSize, props.uploadedAudio, props.isRecording])

  useEffect(() => {
    console.log("running the draw useEffect!")


    if (currentBuffer.length > 0) {
      draw(currentBuffer)
    }
    return () => {

      dispatch(setLoading(false))
    }
  }, [currentBuffer, title, subtitle, offsetX, offsetY, imageHorizontal, imageVertical, titleSize, colorMode, subtitleSize, currentPalette, titleFont, subtitleFont, exportingCanvas, titleVerticalAlign, subtitleVerticalAlign,
    currentGradient, currentMonoColor, titleColor, subtitleColor, canvasColor, barWidth, barHeight, lineWidth, lineHeight, heartTemplate,
    wavePosition, radius, titleAlign, subtitleAlign, waveImage, bgImage, heartPosition, landscape, isSquare, props.buffer, props.ubuffer, titleWeight, titleStyle, subtitleWeight, subtitleStyle])


  const saveDesign = (email, consent) => {
    console.log("calling the saved design");


    draw(currentBuffer);

    let apiEndpoint = "https://a.klaviyo.com/api/track";
    const request = {
      token: "XUfZsn",
      event: "Elected President",
      customer_properties: {
        $email: email,
      },
      properties: {
        //  designURL: buildParameterStringFromState(),
      },
    };
    fetch(apiEndpoint, request)
      .then((response) => response.json())
      .then((data) => console.log("fetch data; " + data))
      .catch((error) => console.log("error: " + error));

    let alreadyInList;
    //  const paramString = buildParameterStringFromState();
    // Check to see if they have consented to promotional emails

    if (consent) {
      // Check if the person is already in the New Subscribers List
      if (alreadyInList) {
        // Just send them the design in the e-mail.
        // Pass paramString as a parameter in the POST call to send the design email.
        // Proceed to Checkout
      } else {
        // Add them to the New Subscribers List
        // Pass paramString as a parameter in the POST call to send the design email.
        // Proceed to Checkout
      }
    } else {
      // Just send them the design in the e-mail.
      // Pass paramString as a parameter in the POST call to send the design email.
      // Proceed to Checkout
    }
    const canvas = waveCanvas.current;
    let img = canvas.toDataURL("image/png");

    let checkoutDesign = {
      uid: "",
      paramString: "", // will have to recreate this once we are done with the navigation.
      image: img,
      orientation: { orientation },
      medium: { medium },
      size: { size }

    }
    dispatch(prepareCheckout(checkoutDesign))


  };

  //----------------
  //because # needs to be converted to work as a url parameter.
  const convertColorsFromStringToArray = (colors) => {
    let newColors = colors.split(",");
    return newColors;
  }

  const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }
  const visualize = (buffer, canvasToUpdate = "all") => {
    console.log("running visualize");
    console.log(buffer);
    buffer = filterData(buffer, sampleSize)
    setCurrentBuffer(buffer)

  }
  function decodeAudioData(recording, audioContext){
console.log("decoding audio data!");
 //   recording = filterData(recording, sampleSize)
   // setCurrentBuffer(recording)
console.log(props.isRecording);
recording = props.isRecording
 
    audioContext.decodeAudioData(
      recording,
      (buffer) => {

        if (buffer) {
          setCurrentUnsampledBuffer(buffer)
          buffer = filterData(buffer, sampleSize)
          setCurrentBuffer(buffer);
          let newUserAudio = {
            id: "",
            buffer: buffer,
            unsampledBuffer: buffer,
            selected: true,
            audioData: { title },
            audio: buffer

          }
          props.addUserAudio(newUserAudio);
        }

      },
      (e) => {
         alert("The file you chose is not a valid audio or the file is unreadable." + e);
      }
    )
    
  }

  function drawSoundwave(audio) {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    if(props.isRecording)decodeAudioData(audio,audioContext)
    else{
      fetch(audio)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {

        audioContext.decodeAudioData(
          arrayBuffer,
          (buffer) => {

            if (buffer) {
              setCurrentUnsampledBuffer(buffer)
              buffer = filterData(buffer, sampleSize)
              setCurrentBuffer(buffer);
              let newUserAudio = {
                id: "",
                buffer: buffer,
                unsampledBuffer: buffer,
                selected: true,
                audioData: { title },
                audio: props.initialAudio

              }
              props.addUserAudio(newUserAudio);
            }

          },
          (e) => {
            // alert("The file you chose is not a valid audio or the file is unreadable." + e);
          }
        )
      }
      )
    }
    


  }

  const filterData = (audioBuffer, sampleSize) => {

    const rawData = audioBuffer.getChannelData(0);
    const samples = sampleSize;
    const blockSize = Math.floor(rawData.length / samples);
    const filteredData = [];
    for (let i = 0; i < samples; i++) {
      let blockStart = blockSize * i;
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j]);
      }
      filteredData.push(sum / blockSize);
    }
    return filteredData
  }


  function drawImageBackgroundTemplate(normalizedData, layer) {

    // DONE! :)

    const bgCanvas = backgroundCanvas.current;
    const wCanvas = waveCanvas.current;
    const exportCanvas = exportingCanvas ? dc.current : null;

    if (exportingCanvas) {
      exportCanvas.width = isSquare ? 1000 : landscape
        ? 1000
        : 750;
      exportCanvas.height = isSquare ? 1000 : landscape
        ? 750
        : 1000;
    }
    bgCanvas.width = isSquare ? 1000 : landscape
      ? 1000
      : 750;
    bgCanvas.height = isSquare ? 1000 : landscape
      ? 750
      : 1000;
    wCanvas.width = isSquare ? 1000 : landscape
      ? 1000
      : 750;
    wCanvas.height = isSquare ? 1000 : landscape
      ? 750
      : 1000;
    // let currentLinewidth = lineWidth
    //  let currentLineHeight = lineHeight
    //this.setState({lineWidth:currentLinewidth * 3, lineHeight:currentLineHeight*3})
    const ctx = bgCanvas.getContext("2d");
    const waveCtx = wCanvas.getContext("2d");
    const destCtx = exportingCanvas ? exportCanvas.getContext("2d") : null;

    const width = wCanvas.width / normalizedData.length
    const dpr = window.devicePixelRatio || 1;
    let bgimg = new Image();
    // let effectiveDPI = bgimg.width / this.state.currentCanvasSelected;

    const color2ToBlend = currentGradient[1]
    const color1ToBlend = currentGradient[0]
    const blendedColorsAreWhite = (color1, color2) => { if (color1 === "#fff" || color2 === "#fff") return true }
    let colorProperties = {
      useGradient: colorMode === "gradient" ? true : false,
      blendedColors: null  // temporary
    }
    if (colorMode === "gradient" && !blendedColorsAreWhite(color1ToBlend, color2ToBlend)) {

      let color1 = hexToRgb(color1ToBlend)
      let color2 = hexToRgb(color2ToBlend)
      color1 = `rgb(${color1.r},${color1.g},${color1.b})`
      color2 = `rgb(${color2.r},${color2.g},${color2.b})`
      let blendedClrs = interpolateColors(color1, color2, sampleSize)

      colorProperties = {
        useGradient: colorMode === "gradient" ? true : false,
        blendedColors: blendedClrs  // temporary
      }
    }

    bgimg.src = bgImage;
    bgimg.onload = () => {
      /// effectiveDPI = bgimg.width / this.state.currentCanvasSelected;
      ctx.scale(1, 1);
      drawImageCover(ctx, bgimg, 0, 40, bgCanvas.width, bgCanvas.height)
      ctx.save();

      if (exportingCanvas) {
        destCtx.drawImage(bgCanvas, 0, 0);
        destCtx.drawImage(wCanvas, 0, 0);
        let exportReadyDesign = dc.current.toDataURL("image/png")
        let myData = {
          subtitle, titleSize, subtitleSize, titleFont, subtitleFont, titleWeight, subtitleWeight, titleVerticalAlign, subtitleVerticalAlign,
          title, titleStyle, subtitleStyle, titleAlign, subtitleAlign, currentPalette, currentGradient, colorMode, titleColor, subtitleColor, canvasColor,
          currentMonoColor, drawMode, wavePosition, lineWidth, lineHeight, barWidth, barHeight, radius, spacing, sampleSize, orientation, size, medium, 
          heartPosition,imageHorizontal,imageVertical
        }
        let newDesign = {
          name: "",
          uid: "",
          image: exportReadyDesign,
          data: myData,
          orientation,
          size,
          medium,
          paramString:buildParameterStringFromState(),

          waveImage: bgImage,
        }

        if (exportReadyDesign) {
          props.prepareCheckout(newDesign)
          dispatch(prepareCheckout(newDesign))
          const { history } = props;
          dispatch(toggleExport())
          history.push("/cart");
        }
      }

    };

    waveCtx.translate(
      0,
      wCanvas.height - wCanvas.height * wavePosition
    );
    for (let i = 0; i < normalizedData.length; i++) {
      let x = width * i;

      let height = normalizedData[i] * wCanvas.height;

      x = width * i;
      height =
        (normalizedData[i] * bgCanvas.height) +
        lineHeight

      if (height < 0) {
        height = 0;
      } else if (height > bgCanvas.height / 2) {
        height = height > bgCanvas.height / 2;
      }
      drawLineSegment(
        waveCtx,
        x,
        height,
        width,
        (i + 1) % 2,
        wCanvas,
        colorProperties, i
      );
      ctx.save();
    }
    drawText(waveCtx, wCanvas);

  }

  function drawClipPathTemplate(normalizedData, layer) {
    // INIT 
    console.log("init clip path template");
    // WIP
    let waveImg = new Image()
    waveImg.src = bgImage;
    const bgCanvas = backgroundCanvas.current;
    const wCanvas = waveCanvas.current;
    const exportCanvas = exportingCanvas ? dc.current : null;

    if (exportingCanvas) {
      exportCanvas.width = isSquare ? 1000 : landscape
        ? 1000
        : 750;
      exportCanvas.height = isSquare ? 1000 : landscape
        ? 750
        : 1000;
    }
    bgCanvas.width = isSquare ? 1000 : landscape
      ? 1000
      : 750;
    bgCanvas.height = isSquare ? 1000 : landscape
      ? 750
      : 1000;
    wCanvas.width = isSquare ? 1000 : landscape
      ? 1000
      : 750;
    wCanvas.height = isSquare ? 1000 : landscape
      ? 750
      : 1000;
    // let currentLinewidth = lineWidth
    //  let currentLineHeight = lineHeight
    //this.setState({lineWidth:currentLinewidth * 3, lineHeight:currentLineHeight*3})
    const ctx = bgCanvas.getContext("2d");
    const waveCtx = wCanvas.getContext("2d");
    const destCtx = exportingCanvas ? exportCanvas.getContext("2d") : null;

    ctx.translate(bgCanvas.width / 2, (bgCanvas.height / 2) - bgCanvas.height * heartPosition);


    ctx.save();
    waveCtx.save();

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, bgCanvas.width, bgCanvas.height);

    waveCtx.setTransform(1, 0, 0, 1, 0, 0);
    waveCtx.clearRect(0, 0, wCanvas.width, wCanvas.height);

    ctx.restore();
    waveCtx.restore();


    const width = wCanvas.width / normalizedData.length
    const dpr = window.devicePixelRatio || 1;
    
    // let effectiveDPI = bgimg.width / this.state.currentCanvasSelected;
    //draw heart shape to be used as clip path


    const leftPadding = (bgCanvas.width * 0.9) / normalizedData.length;
    const rightPadding = bgCanvas.width * 0.05;
    let x, y, theta;
  


  
      ctx.beginPath()
      ctx.fillStyle =  PRIMARY_COLOR;
      //https://mathworld.wolfram.com/HeartCurve.html
      for (theta = 0; theta < (Math.PI * 2); theta += 0.1) {
        x =  radius * 16 * Math.pow(Math.sin(theta), 3);
        y = -radius * (13 * Math.cos(theta) - 5 * Math.cos(2 * theta) - 2 * Math.cos(3 * theta) - Math.cos(4 * theta))
        ctx.lineTo(x, y);
        ctx.fill();
      }

      ctx.globalCompositeOperation = "source-over";
      ctx.save();
    
    ctx.translate((-bgCanvas.width / 2)+bgCanvas.width * 0.03, (bgCanvas.height / 2) - bgCanvas.height * 0.10);
    
      waveImg.onload = () => {
      ctx.globalCompositeOperation = "source-in";
    
        scaleToFit(waveImg, ctx, dpr, bgCanvas);
          //  drawImageCover(ctx, waveImg);
    
          if (exportingCanvas) {
            destCtx.drawImage(bgCanvas, 0, 0);
            destCtx.drawImage(wCanvas, 0, 0);
            let exportReadyDesign = dc.current.toDataURL("image/png")
            let myData = {
              subtitle, titleSize, subtitleSize, titleFont, subtitleFont, titleWeight, subtitleWeight, titleVerticalAlign, subtitleVerticalAlign,
              title, titleStyle, subtitleStyle, titleAlign, subtitleAlign, currentPalette, currentGradient, colorMode, titleColor, subtitleColor, canvasColor,
              currentMonoColor, drawMode, wavePosition, lineWidth, lineHeight, barWidth, barHeight, radius, spacing, sampleSize, orientation, size, medium
            }
    
            let newDesign = {
              name: "",
              uid: "",
              image: exportReadyDesign,
              data: myData,
              orientation,
              size,
              medium,
              waveImage,
              paramString:buildParameterStringFromState(),
            }
            if (exportReadyDesign) {
              props.prepareCheckout(newDesign)
              dispatch(prepareCheckout(newDesign))
              const { history } = props;
              dispatch(toggleExport())
              history.push("/cart");
            }
          }
    
          ctx.save();
        };
    const color2ToBlend = currentGradient[1]
    const color1ToBlend = currentGradient[0]
    const blendedColorsAreWhite = (color1, color2) => { if (color1 === "#fff" || color2 === "#fff") return true }
    let colorProperties = {
      useGradient: colorMode === "gradient" ? true : false,
      blendedColors: null  // temporary
    }
    if (colorMode === "gradient" && !blendedColorsAreWhite(color1ToBlend, color2ToBlend)) {

      let color1 = hexToRgb(color1ToBlend)
      let color2 = hexToRgb(color2ToBlend)
      color1 = `rgb(${color1.r},${color1.g},${color1.b})`
      color2 = `rgb(${color2.r},${color2.g},${color2.b})`
      let blendedClrs = interpolateColors(color1, color2, sampleSize)

      colorProperties = {
        useGradient: colorMode === "gradient" ? true : false,
        blendedColors: blendedClrs  // temporary
      }
    }
    
   
    ctx.globalCompositeOperation = "source-over";
    waveCtx.translate(
      0,
      wCanvas.height - wCanvas.height * wavePosition
    );
    waveCtx.beginPath()
    for (let i = 0; i < normalizedData.length; i++) {
      let x = width * i // * leftPadding + rightPadding;

      let height = normalizedData[i] * wCanvas.height;

   
      height =
        (normalizedData[i] * bgCanvas.height) +
        lineHeight

      if (height < 0) {
        height = 0;
      } else if (height > bgCanvas.height / 2) {
        height = height > bgCanvas.height / 2;
      }
      drawLineSegment(
        waveCtx,
        x,
        height,
        width,
        (i + 1) % 2,
        wCanvas,
        colorProperties, i
      );
      ctx.save();
      waveCtx.save()
      waveCtx.closePath()
    }
    drawText(waveCtx, wCanvas);

  }



  const draw = (normalizedData, canvasToUpdate = "all") => {

    console.log("CALLING DRAW!!");
    let waveImg = new Image()
    console.log("HEART TEMP PROP: " + heartTemplate);
    console.log("title: " + title);
    if (useBG) {
      if(heartTemplate){
        drawClipPathTemplate(normalizedData, canvasToUpdate)
      }
      else{
        drawImageBackgroundTemplate(normalizedData, canvasToUpdate)
      }
    
    }
    else {

      const color1ToBlend = currentGradient[0]
      const color2ToBlend = currentGradient[1]

      const blendedColorsAreWhite = (color1, color2) => { if (color1 === "#fff" || color2 === "#fff") return true }
      let colorProperties = {
        useGradient: false,
        blendedColors: null  // temporary
      }

      if (drawMode !== DRAWMODE.PICTURELINE && !blendedColorsAreWhite(color1ToBlend, color2ToBlend)) {

        let color1 = hexToRgb(color1ToBlend)
        let color2 = hexToRgb(color2ToBlend)
        color1 = `rgb(${color1.r},${color1.g},${color1.b})`
        color2 = `rgb(${color2.r},${color2.g},${color2.b})`
        let blendedClrs = interpolateColors(color1, color2, sampleSize)

        colorProperties = {
          useGradient: colorMode === "gradient" ? true : false,
          blendedColors: blendedClrs  // temporary
        }

      }
      const exportCanvas = dc.current;

      const bgCanvas = backgroundCanvas.current;
      const wCanvas = waveCanvas.current;
      const dpr = window.devicePixelRatio || 1;

      if (exportingCanvas) {
        exportCanvas.width = isSquare ? 1000 : landscape
          ? 1000
          : 750;
        exportCanvas.height = isSquare ? 1000 : landscape
          ? 750
          : 1000;
      }

      // 3000 x 2400, 2880 x1920,
      bgCanvas.width = isSquare ? 1000 : landscape
        ? 1000
        : 750;
      bgCanvas.height = isSquare ? 1000 : landscape
        ? 750
        : 1000;
      wCanvas.width = isSquare ? 1000 : landscape
        ? 1000
        : 750;
      wCanvas.height = isSquare ? 1000 : landscape
        ? 750
        : 1000;
      //    let currentLinewidth = lineWidth;
      //    let currentLineHeight = lineHeight;
      //let currentImageVertical = this.state.imageVertical;

      const ctx = bgCanvas.getContext("2d");

      const waveCtx = wCanvas.getContext("2d");
      const destCtx = exportingCanvas ? exportCanvas.getContext('2d') : null;

      ctx.translate(0, bgCanvas.height / 2);
      waveCtx.translate(
        0,
        wCanvas.height / 2
      );

      ctx.save();
      waveCtx.save();

      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, bgCanvas.width, bgCanvas.height);

      waveCtx.setTransform(1, 0, 0, 1, 0, 0);
      waveCtx.clearRect(0, 0, wCanvas.width, wCanvas.height);

      ctx.restore();
      waveCtx.restore();

      ctx.globalCompositeOperation = "over";
      waveCtx.globalCompositeOperation = "over";

      if (exportingCanvas) {
        destCtx.fillStyle = canvasColor.toString();
        destCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height)
      }


      /* HOW WE IMPLEMENT END PADDING:
      const width = this.state.useBG
      ? (wCanvas.width * 0.9) / normalizedData.length
      : (bgCanvas.width * 0.9) / normalizedData.length;
  */
      const leftPadding = (bgCanvas.width * 0.9) / normalizedData.length;
      const rightPadding = bgCanvas.width * 0.05;

      let x, height;


      waveImg.src = waveImage;

      if (
        drawMode === DRAWMODE.RADIAL ||
        drawMode === DRAWMODE.RADIAL_LINE ||
        drawMode === DRAWMODE.RADIAL_CIRCLE
      ) {
        drawRadial(
          ctx,
          waveCtx,
          wCanvas,
          normalizedData,
          bgCanvas,
          dpr,
          colorProperties
        );

      }
      else if (
        drawMode === DRAWMODE.LINEAR ||
        drawMode === DRAWMODE.LINE ||
        drawMode === DRAWMODE.PICTURELINE
      ) {

        for (let i = 0; i < normalizedData.length; i++) {



          if (drawMode === DRAWMODE.LINEAR) {

            x = rightPadding + leftPadding * i;
            height = normalizedData[i] * bgCanvas.height;

          } else if (drawMode === DRAWMODE.PICTURELINE) {

            x = rightPadding + leftPadding * i;
            height = (normalizedData[i] * bgCanvas.height) + lineHeight

          } else if (drawMode === DRAWMODE.LINE) {

            x = rightPadding + leftPadding * i;
            height = (normalizedData[i] * bgCanvas.height) + lineHeight
          }

          if (height < 0) {
            height = 0;
          } else if (height > bgCanvas.height / 2) {
            height = height > bgCanvas.height / 2;
          }
          drawLineSegment(ctx, x, height, leftPadding, (i + 1) % 2, bgCanvas, colorProperties, i);

          ctx.globalCompositeOperation = "source-over";
          ctx.save();
        }

      }
      if (
        drawMode === DRAWMODE.PICTURELINE
      ) {
        waveImg.onload = () => {
          ctx.globalCompositeOperation = "source-in";

          scaleToFit(waveImg, ctx, dpr, bgCanvas);
          // drawImageCover(ctx, waveImg);

          if (exportingCanvas) {
            destCtx.drawImage(bgCanvas, 0, 0);
            let exportReadyDesign = dc.current.toDataURL("image/png")
            let myData = {
              subtitle, titleSize, subtitleSize, titleFont, subtitleFont, titleWeight, subtitleWeight, titleVerticalAlign, subtitleVerticalAlign,
              title, titleStyle, subtitleStyle, titleAlign, subtitleAlign, currentPalette, currentGradient, colorMode, titleColor, subtitleColor, canvasColor,
              currentMonoColor, drawMode, wavePosition, lineWidth, lineHeight, barWidth, barHeight, radius, spacing, sampleSize, orientation, size, medium, 
              heartPosition,imageHorizontal,imageVertical
            }

            let newDesign = {
              name: "",
              uid: "",
              image: exportReadyDesign,
              data: myData,
              orientation,
              size,
              medium,
              waveImage,
              paramString:buildParameterStringFromState(),
            }
            if (exportReadyDesign) {
              props.prepareCheckout(newDesign)
              dispatch(prepareCheckout(newDesign))
              const { history } = props;
              dispatch(toggleExport())
              history.push("/cart");
            }
          }

          ctx.save();
        };
      }
      else {
        if (exportingCanvas) destCtx.drawImage(bgCanvas, 0, 0);
      }
      drawText(waveCtx, wCanvas);
      if (exportingCanvas) {
        destCtx.drawImage(wCanvas, 0, 0);
        let exportReadyDesign = dc.current.toDataURL("image/png")

        let myData = {
          subtitle, titleSize, subtitleSize, titleFont, subtitleFont, titleWeight, subtitleWeight, titleVerticalAlign, subtitleVerticalAlign,
          title, titleStyle, subtitleStyle, titleAlign, subtitleAlign, currentPalette, currentGradient, colorMode, titleColor, subtitleColor, canvasColor,
          currentMonoColor, drawMode, wavePosition, lineWidth, lineHeight, barWidth, barHeight, radius, spacing, sampleSize, orientation, size, medium, 
          heartPosition,imageHorizontal,imageVertical
        }

        let newDesign = {
          name: "",
          uid: "",
          image: exportReadyDesign,
          data: myData,
          orientation,
          size,
          medium,
          waweImage: null,
          paramString:buildParameterStringFromState(),
        }
        console.log(exportReadyDesign);

        if (exportReadyDesign && drawMode !== DRAWMODE.PICTURELINE) {
          props.prepareCheckout(newDesign)
          dispatch(prepareCheckout(newDesign))
          const { history } = props;
          dispatch(toggleExport())
          history.push("/cart");

        }
      }

    }


  }

  const drawText = (ctx, canvas) => {
    ctx.globalCompositeOperation = "source-over";

    const titleScale = 500;
    const titleRatio = titleSize / titleScale;
    const newTitleSize = canvas.width * titleRatio
    const subtitleRatio = subtitleSize / titleScale;
    const newSubtitleSize = canvas.width * subtitleRatio;
    const textPadding = !textinWave ? 0.050 : 0.010;
    const leftTextAlign = canvas.width * textPadding;
    const centerTextAlign = canvas.width / 2;
    const rightTextAlign = canvas.width - canvas.width * textPadding;

    const topTitleTextAlign = -canvas.height / 2 + canvas.height * 0.12;
    const bottomTitleTextAlign = canvas.height / 2 - canvas.height * 0.12;

    const topSubtitleTextAlign = -canvas.height / 2 + canvas.height * 0.18
    const bottomSubtitleTextAlign = canvas.height / 2 - canvas.height * 0.06

    const titleX = titleAlign === "left" ? leftTextAlign : titleAlign === "center" ? centerTextAlign : rightTextAlign;
    const subtitleX = subtitleAlign === "left" ? leftTextAlign : subtitleAlign === "center" ? centerTextAlign : rightTextAlign;
    const titleY = titleVerticalAlign === "top" ? topTitleTextAlign : bottomTitleTextAlign;
    const subtitleY = subtitleVerticalAlign === "top" ? topSubtitleTextAlign : bottomSubtitleTextAlign;
    const isBold = titleWeight ? "bold" : ""
    const isItalic = titleStyle ? "italic" : ""
    const isSubtitleBold = subtitleWeight ? "bold" : ""
    const isSubtitleItalic = subtitleStyle ? "italic" : ""

    ctx.beginPath();
    ctx.font = `${isBold} ${isItalic} ${newTitleSize}px  ${titleFont}`;
    ctx.fillStyle = titleColor;

    ctx.textAlign = titleAlign;
    ctx.beginPath();
    ctx.fillText(
      title,
      titleX,
      textinWave ? 0 + (canvas.width * titleRatio) / 4 : titleY
    );
    ctx.closePath()

    ctx.beginPath();
    ctx.font = `${isSubtitleBold} ${isSubtitleItalic} ${newSubtitleSize}px  ${subtitleFont}`;
    ctx.textAlign = subtitleAlign;
    ctx.fillStyle = subtitleColor;
    ctx.fillText(
      subtitle,
      subtitleX,
    textinWave?  0 + (canvas.width * 0.22) : subtitleY
    );
    ctx.closePath();

    ctx.save();
  }

  const drawImageCover = (ctx, img, x, y, w, h, ox = 0.5, oy = 0.5) => {
    if (arguments.length === 2) {
      x = y = 0;
      w = ctx.canvas.width;
      h = ctx.canvas.height;
    }


    // keep bounds [0.0, 1.0]
    if (offsetX < 0) changeOffsetX(0);
    if (offsetY < 0) changeOffsetY(0);
    if (offsetX > 1) changeOffsetX(1);
    if (offsetY > 1) changeOffsetY(1);

    var iw = img.width,
      ih = img.height,
      r = Math.min(w / iw, h / ih),
      nw = iw * r,   // new prop. width
      nh = ih * r,   // new prop. height
      cx, cy, cw, ch, ar = 1;

    // decide which gap to fill    
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }

  const scaleToFit = (img, ctx, dpr, canvas) => {
    ctx.scale(1, 1);

    var scale = Math.min(
      canvas.width / img.width,
      canvas.height / img.height
    );

    var y = canvas.height / 2 - img.height * scale

    ctx.drawImage(
      img,
      imageHorizontal,
      landscape ? y + imageVertical : isSquare ? y - canvas.width * 0.15 + imageVertical : y - canvas.width * 0.30 + imageVertical,
      img.width * scale,
      img.height * scale
    );

    ctx.save();

  }

  const drawRadial = (ctx, waveCtx, waveCanvas, normalizedData, canvas, dpr, colorProperties) => {
    let x_end, y_end, bar_height, rads;
    let bars = sampleSize;
    var centerX = canvas.width / 2;
    var centerY = -50;

    ctx.beginPath();

    ctx.arc(centerX, centerY / 2, radius, 0, 2 * Math.PI, false);


    ctx.lineWidth = 1;
    ctx.strokeStyle = "#000";

    for (var i = 0; i < normalizedData.length; i++) {
      rads = (Math.PI * 2) / bars;

      bar_height = normalizedData[i] * canvas.height * 0.7;
      if (bar_height < 0) {
        bar_height = 0;
      } else if (bar_height > canvas.height / 2) {
        bar_height = bar_height > canvas.height / 2;
      }

      let x = centerX + Math.cos(rads * i) * radius;
      let y = centerY + Math.sin(rads * i) * radius;
      x_end = centerX + Math.cos(rads * i) * (radius + bar_height);
      y_end = centerY + Math.sin(rads * i) * (radius + bar_height);
      if (drawMode === DRAWMODE.RADIAL_CIRCLE) {
        // drawLine(x, y, x_end, y_end, ctx, canvas, i, colorProperties);
        // drawCircleLine(x, y, x_end, y_end, ctx, canvas);
      } else {
        drawLine(x, y, x_end, y_end, ctx, canvas, i, colorProperties);
      }
    }
    ctx.closePath();
  }

  const drawLine = (x, y, x2, y2, ctx, canvas, index, colorProperties) => {
    if (drawMode === DRAWMODE.RADIAL_LINE) {
      const a = x - x2;
      const b = y - y2;

      if (colorMode === "gradient") {
        if (colorProperties.blendedColors[index]) {
          let grad = ctx.createLinearGradient(x, y, x2, y2);
          grad.addColorStop(0, currentGradient[0]);
          grad.addColorStop(1, currentGradient[1]);
          ctx.strokeStyle = grad;
        }

      }
      else if (colorMode === "mono") {
        ctx.strokeStyle = currentMonoColor;
      }
      else {
        const distance = Math.sqrt(a * a + b * b);

        ctx.strokeStyle = "#fff"

        if (distance > 0 && distance < 10) {
          ctx.strokeStyle = currentPalette[4];

        }

        if (distance > 10 && distance < 50) {
          ctx.strokeStyle = currentPalette[3];

        }

        if (distance > 50 && distance < 75) {
          ctx.strokeStyle = currentPalette[2];

        }
        if (distance > 75 && distance < 120) {
          ctx.strokeStyle = currentPalette[1];

        }

        if (distance > 120) {
          ctx.strokeStyle = currentPalette[0];

        }
      }


      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x2, y2);
      ctx.stroke();


    }
  }

  const drawLineSegment = (ctx, x, y, width, isEven, canvas, colorProperties, index) => {

    let numColors = currentPalette.length;
    switch (drawMode) {
      default:
      case DRAWMODE.LINEAR:
        ctx.strokeStyle = currentMonoColor;
        ctx.fillStyle = currentMonoColor;


        if (colorMode === "palette") {
          if (y < 40 && y > -5) {
            ctx.fillStyle = currentPalette[0];

          }

          if (y < 90 && y > 40) {
            ctx.fillStyle = currentPalette[1];

          }

          if (y < 150 && y > 90) {
            ctx.fillStyle = currentPalette[2];

          }

          if (y < 350 && y > 150) {
            ctx.fillStyle = currentPalette[3];

          }

          if (y < 450 && y > 350) {
            ctx.fillStyle = currentPalette[4];

          }
        }
        else if (colorMode === "gradient") {
          if (colorProperties.blendedColors[index]) {
            ctx.fillStyle = rgbToHex(colorProperties.blendedColors[index][0], colorProperties.blendedColors[index][1], colorProperties.blendedColors[index][2]);
          }

        }

        let bar_x = x - spacing;
        let bar_height = y + barHeight;


        ctx.beginPath();
        ctx.fillRect(bar_x, (0 - bar_height) / 2, barWidth, bar_height);


        break;
      case DRAWMODE.PICTURE:
        ctx.strokeStyle = currentPalette[0];

        ctx.fillStyle = currentPalette[0];

        let waveX = x;
        let waveWidth = 1.2 + barWidth;
        let waveheight = y + barHeight;
        ctx.beginPath();
        ctx.fillRect(waveX, (0 - waveheight) / 2, waveWidth, waveheight);

        break;

      case DRAWMODE.PICTURELINE:
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "#5f00ba";

        ctx.beginPath();
        y = isEven ? y : -y;
        ctx.moveTo(x, 0);
        ctx.lineTo(x, y);
        //ctx.arc(x + width / 2, y, width / 2, Math.PI, 0, isEven);
        ctx.lineTo(x + width, 0);
        ctx.stroke();



        break;

      case DRAWMODE.LINE:
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = currentPalette[0];


        if (colorMode === "gradient") {

          if (colorProperties.blendedColors[index]) {
            ctx.strokeStyle = rgbToHex(colorProperties.blendedColors[index][0], colorProperties.blendedColors[index][1], colorProperties.blendedColors[index][2]);
          }

        }
        else {
          if (colorMode === "palette") {
            numColors = currentPalette.length;
            switch (numColors) {
              case 1:
                ctx.strokeStyle = currentPalette[0];

                break;

              case 2:

                if (y > 80) {
                  ctx.strokeStyle = currentPalette[0];

                }
                else {
                  ctx.strokeStyle = currentPalette[1];
                }


                break;

              case 3:

                if (y < 40 && y > -5) {
                  ctx.strokeStyle = currentPalette[0];
                }

                if (y < 90 && y > 40) {
                  ctx.strokeStyle = currentPalette[1];
                }

                if (y < 150 && y > 90) {
                  ctx.strokeStyle = currentPalette[2];
                }

                break;

              case 4:

                if (y < 40 && y > -5) {
                  ctx.strokeStyle = currentPalette[0];
                }

                if (y < 90 && y > 40) {
                  ctx.strokeStyle = currentPalette[1];
                }

                if (y < 150 && y > 90) {
                  ctx.strokeStyle = currentPalette[2];
                }

                if (y < 350 && y > 150) {
                  ctx.strokeStyle = currentPalette[3];
                }

              break;
              case 5:

                if (y < 40 && y > -5) {
                  ctx.strokeStyle = currentPalette[0];
                }

                if (y < 90 && y > 40) {
                  ctx.strokeStyle = currentPalette[1];
                }

                if (y < 150 && y > 90) {
                  ctx.strokeStyle = currentPalette[2];
                }

                if (y < 350 && y > 150) {
                  ctx.strokeStyle = currentPalette[3];
                }

                if (y < 450 && y > 350) {
                  ctx.strokeStyle = currentPalette[4];
                }
                break;
              default:
                break;

            }

          }
          else {
            ctx.strokeStyle = currentMonoColor
          }
        }

        ctx.beginPath();
        y = isEven ? y : -y;
        ctx.moveTo(x, 0);
        ctx.lineTo(x, y);
        //   ctx.arc(x + width / 2, y, width / 2, Math.PI, 0, isEven);
        ctx.lineTo(x + width, 0);
        ctx.stroke();

        break;
    }
  }




  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  // Returns a single rgb color interpolation between given rgb color
  // based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
  // based on the code of Zach Saucier @ https://graphicdesign.stackexchange.com/questions/83866/generating-a-series-of-colors-between-two-colors 
  function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) {
      factor = 0.5;
    }
    let interpolatedColor = color1.slice();
    for (var i = 0; i < 3; i++) {
      interpolatedColor[i] = Math.round(interpolatedColor[i] + factor * (color2[i] - color1[i]));
    }
    return interpolatedColor;
  };

  const interpolateColors = (color1, color2, steps) => {
    var stepFactor = 1 / (steps - 1),
      interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);
    for (let i = 0; i < steps; i++) {
      interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }
    return interpolatedColorArray;
  }
  ///




  const buildParameterStringFromState = () => {
    if (props.params) {
      console.log("PARAMS :::::::");
      console.log(props.params);
      if (!params) setParams(props.params);
    }
    if (params !== null) {
      let currentParams = params;

      for (let parameter in currentParams) {
        if (currentParams[parameter] === "") {
          delete currentParams[parameter];
        }
      }
      let parameterString = "";
      for (let parameter in currentParams) {
        if (parameter !== undefined) {
          parameterString =
            parameterString + parameter + "=" + currentParams[parameter] + "&";
        }
      }
      if (parameterString !== undefined) {
        console.log("parameter string: " + "/?" + parameterString);
        return "/?" + parameterString;
      }
    } else {
      console.error("there are no parameters to build a string from!");
      return null;
    }
  }

  // window.scrollTo(0, 0);


  const backgroundCanvasStyle = {

    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  };
  const waveCanvasStyle = {

    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  };




  let canvasContainerStyle = {
    background: canvasColor,
    position: "relative",
    margin: "0 auto",
    width: isSquare ? "80vmin" : landscape ? "88vmin" : "66vmin",
    height: isSquare ? "80vmin" : landscape ? "66vmin" : "88vmin",
    textAlign: "center",
    display: "flex",
    // border:"1.7em solid #24252b",
    Zindex: "50",
    boxShadow:
      "0 1px 2px rgba(0,0,0,0.04),  0 2px 4px rgba(0,0,0,0.04), 0 4px 8px rgba(0,0,0,0.04),  0 8px 16px rgba(0,0,0,0.04),  0 16px 32px rgba(0,0,0,0.04),   0 32px 64px rgba(0,0,0,0.04)",
  };




  return (
    <div className="design"  >
      <div className={medium === MEDIUM.FRAMED ? !landscape && !isSquare ? "framed-portrait" : "framed" : ""}
        style={canvasContainerStyle}
      >
        <CanvasComponent styling={backgroundCanvasStyle} reference={backgroundCanvas} />
        <CanvasComponent styling={waveCanvasStyle} reference={waveCanvas} />
        <CanvasComponent styling={backgroundCanvasStyle} reference={dc} />
        {isLoading ? <CircularProgress name="spinner" size="5rem" style={{ position: "relative", margin: "auto", color: "#5f00ba" }} /> : null}

      </div>

      <ImageResolutionModal />

    </div>
  );

}
